import React, { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Grid, Text } from 'components/elements'
import { colors } from 'libs/theme'

const ChannelIO = memo(() => {
  const { env, user, breakpoints } = useSelector(state => state.default)

  const loadScript = () => {
    const w = window
    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function () {})('ChannelIO script included twice.')
    }
    const ch = function () {
      ch.c(arguments)
    }
    ch.q = []
    ch.c = function (args) {
      ch.q.push(args)
    }
    w.ChannelIO = ch
    function l () {
      if (w.ChannelIOInitialized) return
      w.ChannelIOInitialized = true
      const s = document.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js'
      s.charset = 'UTF-8'
      const x = document.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
      s.onload = () => {
        const settings = {
          pluginKey: env.CHANNEL_TALK_KEY,
          memberId: user ? user.id : undefined
        }
        window.ChannelIO('boot', settings)
      }
    }
    if (document.readyState === 'complete') l()
    else if (window.attachEvent) window.attachEvent('onload', l)
    else {
      window.addEventListener('DOMContentLoaded', l, false)
      window.addEventListener('load', l, false)
    }
  }

  const boot = settings => {
    window.ChannelIO('boot', settings)
  }
  const shutdown = () => {
    window.ChannelIO('shutdown')
  }

  useEffect(() => {
    if (env) loadScript()
  }, [env])

  if (breakpoints?.xl) {
    return (
      <>
        <Grid position='fixed' style={{ bottom: 70, right: 140 }}>
          <Text color={colors.white} variant='subtitle1' align='right'>채널톡 문의하기</Text>
          <Text color={colors.category.stylish.light} variant='caption3' align='right'>서비스 이용 문의, 제휴 상담 등</Text>
        </Grid>
      </>
    )
  } else return null
}, () => {
  return true
})

export default ChannelIO
