import React, { memo } from 'react'
import { CircularProgress, LinearProgress } from '@material-ui/core'
import styled from 'styled-components'

import { colors } from 'libs/theme'

const StyledSpinner = styled(CircularProgress)`
  ${({ size, circlecolor }) => `
    width: ${size}px;
    height: ${size}px;
    color: ${circlecolor};
  `}
`

export const Spinner = memo(({ size = 44, color = colors.primary.normal }) => {
  return (
    <StyledSpinner size={size} circlecolor={color} />
  )
}, () => {
  return true
})

const StyledLinear = styled(LinearProgress)`
  ${({ height, linecolor }) => `
    height: ${height}px;
    background-color: ${linecolor}33;
    > div.MuiLinearProgress-bar {
      background-color: ${linecolor};
    }
  `}
`

export const Linear = memo(({ thickness = 1, color = colors.primary.normal }) => {
  return (
    <StyledLinear height={thickness} linecolor={color} />
  )
}, () => {
  return true
})
