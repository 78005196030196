import { configureStore } from '@reduxjs/toolkit'
import defaultReducer from 'store/default'

export default configureStore({
  reducer: {
    default: defaultReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
})
