import React, { memo } from 'react'
import { Dialog as MDialog, Popover as MPopover, Drawer as MDrawer } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { colors } from 'libs/theme'
import { Grid } from 'components/elements'

export const Dialog = memo(({
  isOpen = false, isFull, close, zIndex = 61, style = {}, children
}) => {
  const { breakpoints, safeAreaInsets } = useSelector(state => state.default)
  return (
    <MDialog open={isOpen} onClose={close} fullScreen={isFull} style={{ zIndex, ...style }}>
      <Grid padding={isFull && breakpoints?.isIos && !!safeAreaInsets ? `${safeAreaInsets.top}px 0 8px` : undefined}>
        {children}
      </Grid>
    </MDialog>
  )
}, (p, n) => {
  if (p.isOpen !== n.isOpen) return false
  if (p.isFull !== n.isFull) return false
  if (p.children !== n.children) return false
  return true
})

export const Popover = memo(({
  isOpen = false, target, close, children,
  radius = '6px', border = `1px solid ${colors.grey.g300}`,
  width, height, minwidth, padding, color, elevation = 0,
  left, right, bottom, overflow,
  anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
  transformOrigin = { vertical: 'top', horizontal: 'center' }
}) => {
  return (
    <MPopover
      open={isOpen}
      anchorEl={target}
      onClose={close}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={{ elevation, style: { borderRadius: radius, border, width, height, minwidth, padding, color, top, left, right, bottom, overflow } }}
      >
      {children}
    </MPopover>
  )
}, (p, n) => {
  if (p.isOpen !== n.isOpen) return false
  if (p.target !== n.target) return false
  return true
})

export const Drawer = memo(({ anchor, isOpen = false, children, onClose }) => {
  return (
    <MDrawer anchor={anchor} open={isOpen} onClose={onClose}>
      {children}
    </MDrawer>
  )
}, (p, n) => {
  if (p.isOpen !== n.isOpen) return false
  return true
})
