import axios from 'axios'
import * as Sentry from '@sentry/nextjs'
import TagManager from 'react-gtm-module'

import { getLocalItem, setLocalItem } from './local'
import { decodeJwt } from './string'
import { setAlert, setIsLoading, setUser } from 'store/default'

const pathSet = {
  signupEmail: '/api/auth/email/signup/',
  verify: '/api/auth/email/verify/',
  refresh: '/api/auth/token/refresh/',
  sendVerify: '/api/auth/email/verify/send/',
  signupSocial: '/api/auth/social/signup/',
  loginEmail: '/api/auth/email/login/',
  loginSns: '/api/auth/social/login/',
  findPassword: '/api/auth/password/reset/',
  resetPassword: '/api/auth/password/reset/confirm/',
  changePassword: '/api/auth/password/change/',
  integration: '/api/auth/integration/',
  unregister: '/api/auth/unregister/',
  nice: '/api/nice/',
  me: '/api/users/me/',
  identify: '/api/users/me/identify/',
  'identity-verified': '/api/users/identity-verified/',
  card: '/api/users/me/cards/',
  events: '/api/users/me/events/',
  notifications: '/api/users/me/notifications/',
  qustions: '/api/users/me/questionnaire/',
  myCommunity: '/api/posts/me/',
  getNotice: '/api/announcements/',
  journal: '/api/v1/journals/',
  journalCategories: '/api/journals/categories/',
  counsel: '/api/posts/',
  clinics: '/api/clinics/',
  prices: '/api/clinics/prices/',
  doctor: '/api/doctors/',
  banners: '/api/banners/',
  medicine: '/api/medicine/',
  ingredients: '/api/medicine/ingredients',
  topBanner: '/api/topBanner/',
  holidays: '/api/holidays/',
  kakaoAuth: '/oauth/token',
  treatments: '/api/v1/treatments/',
  hospital: '/api/v1/hospitals/',
  event: '/api/v1/consulting-requests/',
  questionnaires: '/api/v1/questionnaires/',
  deliveryCompanies: '/api/deliveryCompanies/',
  'clinics-v1': '/api/v1/clinics/',
  'clayful-v1': '/api/v1/clayful/',
  'delivery-v1': '/api/v1/delivery/',
  'me-v1': '/api/v1/users/me/',
  'me-integrated': '/api/v1/users/me/integrated/',
  'doctor-v1': '/api/v1/doctors/',
  wishlist: '/api/wishlist'
}
const createParameter = variable => {
  if (!variable || (variable && Object.keys(variable).length === 0)) return ''
  const queries = []
  Object.keys(variable).forEach(key => {
    if (/^[\w-\]+[[]+[\w-\]+[]]/.test(key)) {
      queries.push(`${variable[key].map(s => `${key}=${s}`).join('&')}`)
    } else {
      // if (Array.isArray(variable[key])) variable[key].map(v => queries.push(`${key}=${v}`))
      // else queries.push(`${key}=${variable[key]}`)
      queries.push(`${key}=${variable[key]}`)
    }
  })
  return '?' + queries.join('&')
}

class Api {
  errorCallback = undefined
  instance = undefined
  paymentTimer = 0
  paymentLimit = 3
  isRefreshed = false
  refresh_token_key = 'refresh_token'
  dispatch = undefined

  constructor (baseURL, dispatch) {
    this.dispatch = dispatch
    this.instance = axios.create({
      baseURL,
      timeout: 100000
    })
  }

  setFcm = async () => {
    const fcmToken = getLocalItem('fcmToken')
    if (fcmToken) await this.request('put', 'me', { fcm_registration_id: fcmToken }, { path: 'fcm' })
  }

  resendEmail = async (email, router) => {
    const { success } = await window.api.request('post', 'sendVerify', { email })
    if (success) {
      router.push(`/account/email?email=${email}`)
    }
  }

  checkSaveLogin = (userId, refreshToken) => {
    const isSave = getLocalItem('is_save_login_time')
    if (isSave && isSave.now * 1000 * 60 * 5 > new Date().getTime()) {
      setLocalItem(this.refresh_token_key, {
        [userId]: refreshToken
      })
      setLocalItem('is_save_login_time', undefined)
    }
  }

  login = async (type, variables) => {
    this.removeHeader(['authorization'])
    if (type === 'email') {
      const { success, data, error } = await this.request('post', 'loginEmail', variables.params, { isLoginCallback: true })
      if (success) {
        const decoded = decodeJwt(data.token)
        if (!decoded) return this.dispatch(setAlert({ body: '잘 못된 토큰입니다.' }))
        if (decoded.is_verified) {
          setLocalItem('token', data.token)
          window.api.setHeader({ authorization: 'Bearer ' + data.token })
          this.checkSaveLogin(decoded.user_id, data.refresh_token)
          this.setFcm()
          return true
        } else {
          this.dispatch(setAlert({
            body: `${decoded.email}은 아직 이메일 인증을 하지 않았습니다. 이메일 인증 링크를 재전송하시겠습니까?`,
            onClick: () => this.resendEmail(decoded.email, variables.router)
          }))
          return false
        }
      } else {
        if (error.data.code === 'authentication_failed') variables.callback()
        else this.dispatch(setAlert({ body: error.data?.detail || '서버 오류입니다.' }))
        return false
      }
    }
  }

  deleteFcm = async () => {
    const fcmToken = getLocalItem('fcmToken')
    if (fcmToken) await this.request('delete', 'me', undefined, { path: 'fcm' })
  }

  logout = () => {
    setLocalItem('is_save_login_time')
    setLocalItem('refresh_token')
    setLocalItem('sns-signup-user')
    setLocalItem('sirs-order-items')
    setLocalItem('sirs-redirect')
    setLocalItem('sirs-counsel-item')
    setLocalItem('sirs-counsel-list')
    setLocalItem('stop-mobile-app-download-time')
    setLocalItem('token')
    setLocalItem('clayful-customer')
    setLocalItem('clayful-token')
    this.removeHeader(['authorization'])
    this.deleteFcm()
    this.dispatch(setUser())
    window.location.href = '/'
  }

  completePayment = async ({ imp_uid, merchant_uid, number }) => {
    return new Promise(resolve => {
      setTimeout(async () => {
        const { success } = await window.api.request('post', 'clinics', {
          imp_uid,
          merchant_uid
        }, {
          path: `${number}/payment/complete/`,
          isErrorCallback: true
        })
        if (success) resolve(true)
        else if (this.paymentLimit > this.paymentTimer) {
          this.paymentTimer += 1
          return resolve(await this.completePayment({ imp_uid, merchant_uid, number }))
        } else {
          resolve(false)
        }
      }, 2000)
    })
  }

  payment = async ({ number, method, success, imp_uid, merchant_uid, router, app_scheme, error_msg }) => {
    if (success) {
      if (method === 'vbank') {
        this.dispatch(setAlert({
          body: '가상계좌 발급이 완료되었습니다.',
          onClick: () => router.replace('/me/reservation?tab=waiting'),
          onClose: () => router.replace('/me/reservation?tab=waiting')
        }))
        return
      }

      this.dispatch(setIsLoading(true))
      const s = await this.completePayment({ imp_uid, merchant_uid, number })
      this.dispatch(setIsLoading(false))
      if (s) {
        TagManager.dataLayer({ dataLayer: { event: 'gtm_event_purchase' } })
        this.dispatch(setAlert({
          body: '결제가 완료되었습니다.',
          onClick: () => router.replace(`/me/reservation/${number}`),
          onClose: () => router.replace(`/me/reservation/${number}`)
        }))
      } else {
        this.dispatch(setAlert({
          body: '결제가 정상적으로 완료되지 않았습니다.',
          onClick: () => router.replace(`/me/reservation/${number}`),
          onClose: () => router.replace(`/me/reservation/${number}`)
        }))
      }
    } else {
      this.dispatch(setAlert({
        body: error_msg || '결제에 실패하였습니다.',
        onClick: () => router.replace(`/me/reservation/${number}`),
        onClose: () => router.replace(`/me/reservation/${number}`)
      }))
    }
  }

  removeHeader = headers => {
    headers.forEach(header => {
      delete this.instance.defaults.headers.common[header]
    })
  }

  setHeader = (headers) => {
    if (headers) {
      Object.keys(headers).forEach(key => {
        this.instance.defaults.headers.common[key] = headers[key]
      })
    }
  }

  setErrorCallback = (errorCallback) => {
    this.errorCallback = errorCallback
  }

  refresh = async (userId, token) => {
    try {
      this.removeHeader(['authorization'])
      const { data } = await this.instance.post(pathSet.refresh, { refresh_token: token })
      setLocalItem('token', data.token)
      setLocalItem(this.refresh_token_key, { [userId]: data.refresh_token })
      window.api.setHeader({ authorization: 'Bearer ' + data.token })
      this.isRefreshed = true
      return true
    } catch ({ response }) {
      return false
    }
  }

  request = async (method, pathName, variable, config = {}) => {
    let query = ''
    if (method === 'get' || method === 'delete') {
      query = createParameter(variable)
      variable = null
    }
    try {
      const response = await this.instance({
        method,
        url: `${pathSet[pathName]}${config.path || ''}${query}`,
        data: variable || config.data,
        params: config.params
      })
      this.isRefreshed = false
      return { data: response.data, success: true, headers: response.headers }
    } catch (error) {
      if (!error.response) {
        Sentry.captureException(error)
        if (this.errorCallback) this.errorCallback({})
        return { success: false, error: {} }
      }
      if (!config.isLoginCallback && error.response.status === 401 && !this.isRefreshed) {
        const token = getLocalItem('token')
        if (token) {
          const decoded = decodeJwt(token)
          const refreshToken = getLocalItem('refresh_token')
          if (decoded && refreshToken && refreshToken[decoded.user_id]) {
            if (await this.refresh(decoded.user_id, refreshToken[decoded.user_id])) {
              return await this.request(method, pathName, variable, config)
            } else {
              this.logout()
              window.location.href = `/account?page=${encodeURIComponent(window.location.pathname + window.location.search)}`
            }
          }
        }
        window.location.href = `/account?page=${encodeURIComponent(window.location.pathname + window.location.search)}`
        return { success: false, error: {} }
      } else if (!config.isErrorCallback) {
        if (this.errorCallback) this.errorCallback(error.response)
      }
      return { success: false, error: error.response }
    }
  }
}

export default Api
