import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Provider, useDispatch } from 'react-redux'
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles'

import store from 'store/'
import { setAlert, setBreakpoints, setENV, setSafeAreaInsets, setIsLoading } from 'store/default'
import GlobalStyle, { size, theme } from 'libs/theme'
import { getLocalItem, getOS } from 'libs/local'
import { heights } from 'libs/items'
import Api from 'libs/api'

import Alert from 'components/commons/Alert'
import PCMain from 'components/commons/PCMain'
import { InitLoading, DefaultLoading } from 'components/commons/Loading'
import Meta from 'components/commons/Meta'
import ChannelIO from 'components/commons/ChannelIO'
import TagManager from 'react-gtm-module'

const Common = ({ env, setisInit, children }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const [isWindow, setisWindow] = useState(false)
  const [breakpoints, setbreakpoints] = useState()

  const setErrorCallback = () => {
    window.api.setErrorCallback(error => {
      console.log(error)
      if (error.data?.detail) dispatch(setAlert({ body: error.data.detail }))
      else dispatch(setAlert({ body: '서버 에러입니다.' }))
    })
  }

  const settingSafeAreaInsets = () => {
    const safeAreaInsets = getLocalItem('safeAreaInsets')
    if (safeAreaInsets) dispatch(setSafeAreaInsets(safeAreaInsets))
  }

  const setSize = (width, height) => {
    const breakpoints = {}
    Object.keys(size).forEach(s => {
      breakpoints[s] = size[s] <= width
    })
    const { os, isWebview } = getOS()
    breakpoints.os = os
    breakpoints.isIos = os === 'ios' && isWebview
    breakpoints.isAndroid = os === 'android' && isWebview
    breakpoints.isWebview = isWebview
    breakpoints.width = width > heights.maxWidth ? heights.maxWidth : width
    breakpoints.height = height
    setbreakpoints(breakpoints)
    dispatch(setBreakpoints(breakpoints))
  }

  const resizeEvent = e => {
    setSize(e.target.innerWidth, e.target.innerHeight)
  }

  const checkOS = () => {
    if (getOS().os === 'window') setisWindow(true)
  }

  const setRouterEvent = () => {
    router.events.on('routeChangeStart', (url) => {
      dispatch(setIsLoading(true))
    })
    router.events.on('routeChangeComplete', () => {
      dispatch(setIsLoading(false))
    })
  }

  useEffect(() => {
    if (router) setRouterEvent()
  }, [router])

  useEffect(() => {
    TagManager.initialize({ gtmId: env.GOOGLE_GTM_ID })
  }, [])

  useEffect(() => {
    dispatch(setENV(env))
    checkOS()
    setisInit(true)
    if (
      (window.location.host.indexOf('localhost') === -1 && env.ENV === 'production' && window.location.host.indexOf('www') < 0 && window.location.host.indexOf('staging') < 0) || window.location.host.indexOf('co.kr') > -1
    ) {
      const location = window.location
      window.location.href = `${env.WEB_URL}${location.pathname}${location.search}`
    } else {
      setErrorCallback()
      setSize(window.innerWidth)
      settingSafeAreaInsets()
      window.addEventListener('resize', resizeEvent, false)
      return () => {
        window.removeEventListener('resize', resizeEvent, false)
      }
    }
  }, [])

  return (
    <div className={`layout${breakpoints?.md ? ' isPc' : ''}`}>
      {isWindow && <link rel='stylesheet' href='/css/window.css' />}
      {breakpoints?.md && <PCMain />}
      {children}
      <Alert />
      <DefaultLoading />
      {breakpoints?.lg && <ChannelIO />}
      {breakpoints?.md && <div className='background' />}
    </div>
  )
}

const SetApi = ({ env }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    window.api = new Api(env.API_URL, dispatch)
  }, [])
  return <></>
}

const Layout = ({ env, children }) => {
  const [isInit, setisInit] = useState(false)
  const [isTiming, setisTiming] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setisTiming(true)
    }, 2000)
  }, [])

  return (
    <>
      {(!isTiming || !isInit) && <InitLoading />}
      <Meta url={env.WEB_URL} />
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <Provider store={store}>
            <SetApi env={env} />
            <Common env={env} setisInit={setisInit}>
              {children}
            </Common>
          </Provider>
          <GlobalStyle />
        </StylesProvider>
      </ThemeProvider>
    </>
  )
}

export default Layout
