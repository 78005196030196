import { createGlobalStyle } from 'styled-components'
import { createTheme } from '@material-ui/core/styles'

export const colors = {
  primary: {
    normal: '#002587',
    dark: '#001856',
    light: '#0039AA'
  },
  category: {
    sex: '#FF7070',
    hair: '#FFB800',
    sleep: '#00CCC0',
    body: '#FFB800',
    skin: '#FFB800',
    dental: '#FFB800',
    lifestyle: '#FFB800',
    diet: '#FF7070',
    stylish: {
      dark: '#FFB800',
      light: '#FEDE4B'
    },
    enjoy: {
      dark: '#FF7070',
      light: '#FFA5A5'
    },
    relax: {
      dark: '#00CCC0',
      light: '#3BDFD5'
    }
  },
  white: '#ffffff',
  black: '#000000',
  error: '#FF3B30',
  warning: '#FFCC00',
  success: '#34C759',
  info: '#007AFF',
  transparent: 'transparent',
  active: '#171E2A',
  disabled: '#D2DADE',
  background: '#171E2A',
  border: {
    white: '#ffffff',
    light: '#D2DADE',
    dark: '#455056'
  },
  shadow: {
    light: '#00000005',
    dark: '#00000010'
  },
  /*
  text: {
    t500: '#8B989E',
    t600: '#69767C',
    t800: '#272E32',
    t900: '#171E2A'
  },
  */
  grey: {
    g100: '#FAFAFA',
    g200: '#F0F0F0',
    g300: '#E7E7E7',
    g400: '#D8D8D8',
    g500: '#C4C4C4',
    g600: '#A5A5A5',
    g700: '#7A7A7A',
    g800: '#292929'
  }
}

export const fonts = [
  'Spoqa Han Sans Neo', 'Roboto', 'Droid Sans', 'Malgun Gothic', 'Helvetica', 'Apple-Gothic', '애플고딕', 'Tahoma', 'dotum', '돋움', 'gulim', '굴림', 'sans-serif'
]

export const spaces = {
  default: { px: '20px', n: 20 },
  contents: {
    s1: { px: '24px', n: 24 },
    s2: { px: '36px', n: 36 },
    s3: { px: '48px', n: 48 },
    s4: { px: '64px', n: 64 },
    s5: { px: '96px', n: 96 }
  },
  component: {
    s1: { px: '2px', n: 2 },
    s2: { px: '4px', n: 4 },
    s3: { px: '8px', n: 8 },
    s4: { px: '16px', n: 16 }
  },
  card: {
    padding: { px: '16px', n: 16 },
    gap: { px: '8px', n: 8 }
  },
  column: {
    s2: { px: '8px', n: 8 },
    s3: { px: '10px', n: 10 },
    more: { px: '8px', n: 8 }
  },
  radius: {
    tag: { px: '4px', n: 6 },
    small: { px: '8px', n: 8 },
    medium: { px: '12px', n: 12 },
    image: { px: '14px', n: 14 },
    card: { px: '16px', n: 16 },
    bigImage: { px: '24px', n: 24 }
  },
  s0: { px: '4px', n: 4 },
  s1: { px: '6px', n: 6 },
  s2: { px: '9px', n: 9 },
  s3: { px: '14px', n: 14 },
  s4: { px: '20px', n: 20 },
  s5: { px: '30px', n: 30 },
  s6: { px: '45px', n: 45 },
  s7: { px: '68px', n: 68 },
  s8: { px: '104px', n: 104 },
  s9: { px: '156px', n: 156 }
}

export const fontSizes = {
  primary: 14,
  responsive: {
    sm: 12
  },
  heading1: '32px',
  heading2: '28px',
  heading3: '28px',
  title1: '24px',
  title2: '24px',
  title3: '20px',
  title4: '20px',
  subtitle1: '17px',
  subtitle2: '17px',
  subtitle3: '17px',
  body1: '15px',
  body2: '15px',
  body3: '15px',
  caption1: '13px',
  caption2: '13px',
  caption3: '13px',
  label1: '11px',
  label2: '11px',
  f0: '0.8rem',
  f1: '1rem',
  f2: '1.12rem',
  f3: '1.3rem',
  f4: '1.6rem',
  f5: '2rem',
  f6: '2.5rem',
  f7: '3rem'
}

export const fontWeights = {
  heading1: '700',
  heading2: '700',
  heading3: '400',
  title1: '700',
  title2: '400',
  title3: '700',
  title4: '400',
  subtitle1: '700',
  subtitle2: '500',
  subtitle3: '400',
  body1: '700',
  body2: '500',
  body3: '400',
  caption1: '700',
  caption2: '500',
  caption3: '400',
  label1: '700',
  label2: '500'
}

export const fontLineHegiths = {
  heading1: '46px',
  heading2: '38px',
  heading3: '38px',
  title1: '32px',
  title2: '32px',
  title3: '28px',
  title4: '28px',
  subtitle1: '24px',
  subtitle2: '24px',
  subtitle3: '24px',
  body1: '20px',
  body2: '20px',
  body3: '20px',
  caption1: '18px',
  caption2: '18px',
  caption3: '18px',
  label1: '14px',
  label2: '14px'
}

export const size = {
  xs: 480,
  sm: 576,
  md: 1024,
  lg: 1336,
  xl: 1920,
  xxl: 100000
}

export const device = {
  xs: `(max-width: ${size.xs}px)`,
  sm: `(max-width: ${size.sm}px)`,
  md: `(max-width: ${size.md}px)`,
  lg: `(max-width: ${size.lg}px)`,
  xl: `(max-width: ${size.xl}px)`,
  xxl: `(max-width: ${size.xxl}px)`
}
export const theme = createTheme({
  typography: {
    fontFamily: fonts
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 400,
        minWidth: 'inherit',
        padding: 'inherit',
        lineHeight: 1.4,
        '&:disabled': {
          color: 'inherit'
        }
      },
      text: {
        padding: 'inherit'
      }
    },
    MuiDialog: {
      paperScrollPaper: {
        minWidth: 300
      },
      paperWidthSm: {
        maxWidth: '90%'
      },
      paper: {
        margin: 0
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: spaces.radius.card.px
      }
    }
  }
})

const GlobalStyle = createGlobalStyle`
  html {
    margin: 0;
    padding: 0;
    line-height: 1.4;
  }
  body {
    margin: 0;
    padding: 0;
  }
  textarea {
    border: 1px solid ${colors.grey.g300};
    border-radius: ${spaces.radius.small.px};
    font-family: ${fonts.join(', ')};
    line-height: 1.4;
    &:focus {
      -moz-appearance:none;
      outline:0px none transparent;
      border: 1px solid ${colors.grey.g600};
    }
  }
  @media screen and (width: ${fontSizes.responsive.sm}) {
    html {
      font-size: ${fontSizes.responsive.sm};
    }
  }
`

export default GlobalStyle
