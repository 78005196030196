import React, { memo } from 'react'
import styled from 'styled-components'
import DOMPurify from 'dompurify'

import { device, fontLineHegiths, fontSizes, fontWeights } from 'libs/theme'
import { setProp } from 'libs/string'
import { purifyItems } from 'libs/items'

const createGridResponsive = (props, type) => {
  if (!props) return ''
  const {
    columns, rows, position, align, justify, gap, display, isInline,
    padding, margin, marginTop, border, borderRadius, color,
    width, maxWidth, minWidth,
    height, maxHeight, minHeight
  } = props
  return ` 
    @media screen and ${device[type]} {
      ${setProp('grid-template-columns', columns)}
      ${setProp('grid-template-rows', rows)}
      ${setProp('grid-gap', gap)}
      ${setProp('padding', padding)}
      ${setProp('margin', margin)}
      ${setProp('margin-top', marginTop)}
      ${setProp('align-items', align)}
      ${setProp('align-content', align)}
      ${setProp('justify-items', justify)}
      ${setProp('justify-content', justify)}
      ${setProp('position', position)}
      ${setProp('border', border)}
      ${setProp('border-radius', borderRadius)}
      ${setProp('background-color', color)}
      ${setProp('height', height)}
      ${setProp('min-height', minHeight)}
      ${setProp('max-height', maxHeight)}
      ${setProp('width', width)}
      ${setProp('min-width', minWidth)}
      ${setProp('max-width', maxWidth)}
      ${setProp('display', display || (isInline && 'inline-grid'))}
    }
  `
}

const StyledGrid = styled.div`
  ${p => `
    display: ${p.display || (p.isInline ? 'inline-grid' : 'grid')};
    ${setProp('width', p.width || (p.isInline ? 'fit-content' : undefined))}
    ${setProp('grid-template-columns', p.columns)}
    ${setProp('grid-template-rows', p.rows)}
    ${setProp('grid-gap', p.gap)}
    ${setProp('padding-top', p.paddingTop)}
    ${setProp('padding', p.padding)}
    ${setProp('margin', p.margin)}
    ${setProp('margin-top', p.marginTop)}
    ${setProp('align-items', p.align)}
    ${setProp('align-content', p.align)}
    ${setProp('justify-content', p.justify)}
    ${setProp('justify-items', p.justify)}
    ${setProp('position', p.position)}
    ${setProp('border', p.border)}
    ${setProp('border-radius', p.borderRadius)}
    ${setProp('border-width', p.borderWidth)}
    ${setProp('border-color', p.borderColor)}
    ${setProp('border-style', p.borderStyle)}
    ${setProp('background-color', p.color)}
    ${setProp('height', p.height)}
    ${setProp('min-height', p.minHeight)}
    ${setProp('max-height', p.maxHeight)}
    ${setProp('min-width', p.minWidth)}
    ${setProp('max-width', p.maxWidth)}
    ${setProp('cursor', p.cursor)}
    ${setProp('overflow', p.overflow)}
    ${p.display === 'flex' ? setProp('flex-wrap', p.flexWrap) : ''}
    ${setProp('flex-shrink', p.flexShrink)}
    ${setProp('flex-grow', p.flexGrow)}
    ${setProp('flex-direction', p.flexDirection)}
    ${setProp('flex-basis', p.flexBasis)}
    ${
      p.image ? `
        background-image: ${p.imageGradient ? `linear-gradient( rgba(0, 0, 0, ${p.imageGradient}) 100%, rgba(0, 0, 0, ${p.imageGradient}) 100%), ` : ''}url(${p.image});
        background-position: ${p.imagePosition};
        background-repeat: no-repeat;
        background-size: ${p.imageSize};
      ` : ''
    }
    ${
      p.transition ? `
        transition-property: ${p.transition};
        transition-duration: ${p.transitionDuration};
        transition-timing-function: ${p.transitionTiming};
        ${setProp('transition-delay', p.transitionDelay)}
      ` : ''
    }
    ${createGridResponsive(p.xxl, 'xxl')}
    ${createGridResponsive(p.xl, 'xl')}
    ${createGridResponsive(p.lg, 'lg')}
    ${createGridResponsive(p.md, 'md')}
    ${createGridResponsive(p.sm, 'sm')}
    ${createGridResponsive(p.xs, 'xs')}
  `
}
`

/**
 *** grid component ***
 *
 * columns: string, grid-template-columns
 * rows: string, grid-template-rows
 * display: string, default - grid
 * isInline: boolean, display를 inline-grid로, width를 fit-content로 변경
 * position: string,
 * align: string, align-conetent, align-items 같이 적용
 * justify: string, justify-content, justify-items 같이 적용
 * gap: string
 * color: hash color, background-color
 * padding: string
 * paddingTop: string
 * margin: string
 * marginTop: string
 * border: string
 * borderRadius: string
 * borderColor: hash color
 * borderStyle: string
 * width: string
 * maxWidth: string
 * minWidth: string
 * height: string
 * maxHeight: string
 * minHeight: string
 * overflow: string
 * cursor: string
 * image: url, blob
 * imagePosition: string, default - center
 * imageSize: string, default - cover
 * imageGradient: number, linear-gradient color
 * transition: string, transition-property
 * transitionDuration: string
 * transitionDelay: string
 * transitionTiming: string
 * flexShrink: string
 * flexWrap: string
 * flexGrow: string
 * flexDirection: string
 * flexBasis: string
 * xs, sm, md, lg, xl, xxl: 상위 속성에 대한 각 사이즈별 overriding 기능, ex) sm={{ columns: '1fr' }}
 * children: ReactNode | string
 * style
 * className: string
 * id: string
 * html: string, html code
 * setref: ref 설정을 위한 코드
 * onClick
**/
export const Grid = memo(({
  columns, rows, display, isInline = false, position, align, justify, gap, color,
  padding, paddingTop, margin, marginTop,
  border, borderRadius, borderWidth, borderColor, borderStyle,
  width, maxWidth, minWidth,
  height, maxHeight, minHeight,
  overflow, cursor,
  image, imagePosition = 'center', imageSize = 'cover', imageGradient,
  transition, transitionDuration = '0.2s', transitionDelay, transitionTiming = 'ease-out',
  flexShrink, flexWrap = 'nowrap', flexGrow, flexDirection, flexBasis,
  xs, sm, md, lg, xl, xxl,
  children, style, className, id, html, setref,
  onClick
}) => {
  return (
    <StyledGrid columns={columns} rows={rows} display={display} isInline={isInline} position={position} align={align} justify={justify} gap={gap}
                padding={padding} paddingTop={paddingTop} margin={margin} marginTop={marginTop} color={color}
                border={border} borderRadius={borderRadius} borderWidth={borderWidth} borderColor={borderColor} borderStyle={borderStyle}
                width={width} maxWidth={maxWidth} minWidth={minWidth} height={height} maxHeight={maxHeight} minHeight={minHeight}
                overflow={overflow} cursor={cursor}
                image={image} imagePosition={imagePosition} imageSize={imageSize} imageGradient={imageGradient}
                transition={transition} transitionDuration={transitionDuration} transitionDelay={transitionDelay} transitionTiming={transitionTiming}
                flexShrink={flexShrink} flexWrap={flexWrap} flexGrow={flexGrow} flexDirection={flexDirection} flexBasis={flexBasis}
                xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}
                style={style} className={className} id={id} dangerouslySetInnerHTML={!!html ? { __html: DOMPurify.sanitize(html, { ALLOWED_TAGS: purifyItems.tags, ADD_ATTR: purifyItems.attr }) } : undefined}
                onClick={onClick} ref={setref}>
      {children}
    </StyledGrid>
  )
}, (p, n) => {
  if (p.children !== n.children) return false
  if (p.style !== n.style) return false
  if (p.className !== n.className) return false
  if (p.color !== n.color) return false
  if (p.image !== n.image) return false
  return true
})

const createTextResponsive = (props, type) => {
  if (!props) return ''
  const {
    position, textDecoration,
    padding, margin, marginTop, backgroundColor,
    width, height,
    overflow, ellipsis, size,
    color, lineHeight, align
  } = props
  return `
    @media screen and ${device[type]} {
      ${setProp('color', color)}
      ${setProp('position', position)}
      ${setProp('padding', padding)}
      ${setProp('margin', margin)}
      ${setProp('margin-top', marginTop)}
      ${setProp('width', width)}
      ${setProp('height', height)}
      ${setProp('text-decoration', textDecoration)}
      ${setProp('background-color', backgroundColor)}
      ${setProp('line-height', lineHeight)}
      ${setProp('font-size', size)}
      ${setProp('text-align', align)}
      ${ellipsis ? `
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          -webkit-line-clamp: ${ellipsis};
          -webkit-box-orient: vertical;
        ` : setProp('overflow', overflow)
      }
    }
  `
}

const StyledText = styled.div`
  ${({
    display, position, textDecoration,
    padding, margin, marginTop, backgroundColor,
    width, height,
    overflow, ellipsis, size, align, disabled,
    color, lineHeight, bold, weight, isBreak,
    xs, sm, md, lg, xl, xxl
  }) => `
      ${setProp('word-wrap', isBreak ? 'break-word' : undefined)}
      ${setProp('display', display || 'inline-block')}
      ${setProp('color', disabled ? `${color}66` : color)}
      ${setProp('position', position)}
      ${setProp('padding', padding)}
      ${setProp('margin', margin)}
      ${setProp('margin-top', marginTop)}
      ${setProp('width', width)}
      ${setProp('height', height)}
      ${setProp('text-decoration', textDecoration)}
      ${setProp('background-color', backgroundColor)}
      ${setProp('line-height', lineHeight)}
      ${setProp('font-size', size)}
      ${setProp('text-align', align)}
      ${bold ? 'font-weight: 700;' : setProp('font-weight', weight)}
      ${ellipsis ? `
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        ${ellipsis === 1 ? 'white-space: nowrap;' : ''}
        display: -webkit-box;
        -webkit-line-clamp: ${ellipsis};
        -webkit-box-orient: vertical;
      `
        : setProp('overflow', overflow)
      }
      ${createTextResponsive(xxl, 'xxl')}
      ${createTextResponsive(xl, 'xl')}
      ${createTextResponsive(lg, 'lg')}
      ${createTextResponsive(md, 'md')}
      ${createTextResponsive(sm, 'sm')}
      ${createTextResponsive(xs, 'xs')}
    `
  }
`

/**
 *** 문구를 사용하기 위한 기능을 모아놓은 component ***

 * display: string, default - inline-block
 * position: string
 * textDecoration: string
 * padding: string
 * margin: string
 * marginTop: string, margin 보다 우선 적용된다.
 * backgroundColor: string
 * width: string
 * height: string
 * overflow: string
 * ellipsis: number, ...처리를 위한 기능 => 1일 경우 한줄 ...처리
 * size: string, font-size
 * align: string, text-align
 * disabled: boolean, true 일 경우 불투명 적용
 * isBreak: boolean, default - false, word-wrap의 break-word 속성 여부
 * color: string
 * lineHeight: string
 * bold: boolean
 * weight: string | number, font-weight
 * variant: font-size와 line-height, font-weight에 대한 약속된 디자인 규칙, libs > theme.js > fontSizes, fontWeights, fontLineHegiths 참조
 * xs, sm, md, lg, xl, xxl: 상위 속성에 대한 각 사이즈별 overriding 기능, ex) sm={{ bold: false }}
 * html: string, html code
 */
export const Text = memo(({
  display, position, textDecoration,
  padding, margin, marginTop, backgroundColor,
  width, height,
  overflow, ellipsis, size, align, disabled = false, isBreak = true,
  color, lineHeight, bold = false, weight, variant = 'body3',
  xs, sm, md, lg, xl, xxl,
  children, className, id, style, html
}) => {
  return (
    <StyledText display={display} position={position} textDecoration={textDecoration} isBreak={isBreak}
                padding={padding} margin={margin} marginTop={marginTop} backgroundColor={backgroundColor}
                width={width} height={height} overflow={overflow} ellipsis={ellipsis} align={align} disabled={disabled}
                color={color} lineHeight={lineHeight || fontLineHegiths[variant]} bold={bold} weight={weight || fontWeights[variant]} size={size || fontSizes[variant]}
                xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}
                style={style} className={className} id={id} dangerouslySetInnerHTML={!!html ? { __html: DOMPurify.sanitize(html, { ALLOWED_TAGS: purifyItems.tags, ADD_ATTR: purifyItems.attr }) } : undefined}>
      {children}
    </StyledText>
  )
}, (p, n) => {
  if (p.children !== n.children) return false
  if (p.color !== n.color) return false
  if (p.html !== n.html) return false
  return true
})
