import 'modules/css/_style.scss'
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'
import 'modules/css/custom.scss'
import Layout from 'components/layouts'
import ClayfulLayout from 'components/layouts/clayful'

const Sirs = ({ Component, pageProps }) => {
  const env = {
    ENV: process.env.ENV,
    API_URL: process.env.API_URL,
    WEB_URL: process.env.WEB_URL,
    NAVER_KEY: process.env.NAVER_KEY,
    NAVER_MAP_KEY: process.env.NAVER_MAP_KEY,
    KAKAO_KEY: process.env.KAKAO_KEY,
    IAMPORT: process.env.IAMPORT,
    APP_DOWNLOAD: process.env.APP_DOWNLOAD,
    APP_SCHEME: process.env.APP_SCHEME,
    GOOGLE_GA_ID: process.env.GOOGLE_GA_ID,
    GOOGLE_GTM_ID: process.env.GOOGLE_GTM_ID,
    CHANNEL_TALK_KEY: process.env.CHANNEL_TALK_KEY,
    CLAYFUL_TOKEN: process.env.CLAYFUL_TOKEN,
    CLAYFUL_EVENT_ITEM_ID: process.env.CLAYFUL_EVENT_ITEM_ID,
    CLAYFUL_MAIN_COLLECTION_ID: process.env.CLAYFUL_MAIN_COLLECTION_ID,
    APPLE_APP_ID: process.env.APPLE_APP_ID
  }
  return (
    <Layout env={env}>
      <Component {...pageProps} />
    </Layout>
  )
}

export default Sirs
