import { createSlice } from '@reduxjs/toolkit'

export const defaultSlice = createSlice({
  name: 'default',
  initialState: {
    user: undefined,
    alert: undefined,
    isLoading: false,
    breakpoints: {},
    env: undefined,
    safeAreaInsets: undefined,
    banner: false,
    clayful: undefined,
    isOpenNotification: false
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setAlert: (state, action) => {
      state.alert = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setBreakpoints: (state, action) => {
      state.breakpoints = action.payload
    },
    setENV: (state, action) => {
      state.env = action.payload
    },
    setSafeAreaInsets: (state, action) => {
      state.safeAreaInsets = action.payload
    },
    setBanner: (state, action) => {
      state.banner = action.payload
    },
    setClayful: (state, action) => {
      state.clayful = action.payload
    },
    setIsOpenNotification: (state, action) => {
      state.isOpenNotification = action.payload
    }
  }
})

export const {
  setUser, setAlert, setIsLoading, setBreakpoints,
  setENV, setSafeAreaInsets, setBanner, setClayful, setIsOpenNotification
} = defaultSlice.actions

export default defaultSlice.reducer
