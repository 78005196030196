import axios from 'axios'
import TagManager from 'react-gtm-module'

import { colors } from 'libs/theme'
import { getLocalItem, setLocalItem } from 'libs/local'
import { decodeJwt } from 'libs/string'

export const errorMessages = {
  'peer-select-required': '시스템 오류입니다. 관리자에게 문의해주세요. (상품 옵션 설정 오류)',
  'primary-currency-required': '시스템 오류입니다. 관리자에게 문의해주세요. (상점 설정 오류)',
  'password-required': '비밀번호가 필요합니다.',
  'insecure-authentication': '시스템 오류입니다. 관리자에게 문의해주세요. (잘 못된 사용자 설정)',
  'duplicated-userId': '이미 사용중인 아이디입니다.',
  'duplicated-email': '이미 사용중인 이메일입니다.',
  'invalid-password': '비밀번호가 틀렸습니다. 비밀번호를 확인해주세요.',
  'invalid-mobile-number-format': '잘 못된 핸드폰 번호 형식입니다. 핸드폰 번호를 확인해주세요.',
  'invalid-phone-number-format': '잘 못된 연락처 형식입니다. 연락처 정보를 확인해주세요.',
  'invalid-field-image': '시스템 오류입니다. 관리자에게 문의해주세요. (이미지 설정 오류)',
  'invalid-meta-image': '시스템 오류입니다. 관리자에게 문의해주세요. (메타 이미지 설정 오류)',
  'invalid-model-image': '시스템 오류입니다. 관리자에게 문의해주세요. (모델 이미지 설정 오류)',
  'invalid-postcode': '잘못된 우편번호 형식입니다. 우편번호를 확인해주세요.',
  'not-supported-language': '시스템 오류입니다. 관리자에게 문의해주세요. (지원하지 않는 언어 설정)',
  'not-supported-payment-method': '정기 구독 결제를 지원하지 않는 결제방식입니다.',
  'postcode-required': '우편번호를 입력해주세요.',
  'race-condition': '중복 요청이 있습니다. 잠시 후에 다시 시도해주세요.',
  'not-existing-customer': '존재하지 않는 사용자입니다.',
  'not-existing-group': '시스템 오류입니다. 관리자에게 문의해주세요. (존재하지 않는 고객 그룹)',
  'not-existing-product-relation': '시스템 오류입니다. 관리자에게 문의해주세요. (제품 정보 확인 필요)',
  'not-existing-brand-relation': '시스템 오류입니다. 관리자에게 문의해주세요. (브랜드 정보 확인 필요)',
  'not-existing-collection-relation': '시스템 오류입니다. 관리자에게 문의해주세요. (콜랙션 정보 확인 필요)',
  'not-existing-vendor-relation': '시스템 오류입니다. 관리자에게 문의해주세요. (입점사 정보 확인 필요)',
  'not-existing-customer-relation': '시스템 오류입니다. 관리자에게 문의해주세요. (고객 정보 확인 필요)',
  'not-existing-group-relation': '시스템 오류입니다. 관리자에게 문의해주세요. (그룹 정보 확인 필요)',
  'not-existing-wishlist-relation': '시스템 오류입니다. 관리자에게 문의해주세요. (위시리스트 정보 확인 필요)',
  'not-existing-cart-relation': '시스템 오류입니다. 관리자에게 문의해주세요. (카트 정보 확인 필요)',
  'not-existing-order-relation': '시스템 오류입니다. 관리자에게 문의해주세요. (주문 정보 확인 필요)',
  'not-existing-subscription-relation': '시스템 오류입니다. 관리자에게 문의해주세요. (정기구독 정보 확인 필요)',
  'not-existing-review-relation': '시스템 오류입니다. 관리자에게 문의해주세요. (후기 정보 확인 필요)',
  'not-existing-catalog-relation': '시스템 오류입니다. 관리자에게 문의해주세요. (카탈로그 정보 확인 필요)',
  'not-existing-coupon-relation': '시스템 오류입니다. 관리자에게 문의해주세요. (쿠폰 정보 확인 필요)',
  'not-existing-image-relation': '시스템 오류입니다. 관리자에게 문의해주세요. (이미지 정보 확인 필요)',
  'not-existing-store-relation': '시스템 오류입니다. 관리자에게 문의해주세요. (스토어 정보 확인 필요)',
  'not-existing-country-relation': '시스템 오류입니다. 관리자에게 문의해주세요. (지원 국가 정보 확인 필요)',
  'not-existing-currency-relation': '시스템 오류입니다. 관리자에게 문의해주세요. (통화 정보 확인 필요)',
  'not-existing-meta-image-relation': '시스템 오류입니다. 관리자에게 문의해주세요. (존재하지 않는 메타 이미지)',
  'not-existing-product': '존재하지 않는 상품입니다.',
  'not-existing-brand': '존재하지 않는 브랜드입니다.',
  'not-existing-collection': '존재하지 않는 카테고리입니다.',
  'not-existing-vendor': '존재하지 않는 입점사입니다.',
  'model-exceeded': '제한 갯수를 초과하였습니다.',
  'bundle-product': '시스템 오류입니다. 관리자에게 문의해주세요. (번들 상품은 위시리스트에 담을 수 없습니다)',
  'duplicated-product': '위시리스트에 이미 담겨있는 상품입니다.',
  'items-exceeded': '최대 가능 수량을 초과하였습니다.',
  'no-item': '내역이 존재하지 않습니다.',
  'cart-coupon-category': '시스템 오류입니다. 관리자에게 문의해주세요. (쿠폰 설정 오류)',
  'product-coupon-category': '시스템 오류입니다. 관리자에게 문의해주세요. (쿠폰 설정 오류)',
  'shipping-coupon-category': '시스템 오류입니다. 관리자에게 문의해주세요. (쿠폰 설정 오류)',
  'cart-coupon-only': '중복 할인 적용이 안되는 쿠폰이 사용되었습니다.',
  'product-coupon-only': '중복 할인 적용이 안되는 쿠폰이 사용되었습니다.',
  'shipping-coupon-only': '중복 할인 적용이 안되는 쿠폰이 사용되었습니다.',
  'cart-coupon-price': '쿠폰 사용 가능한 가격범위를 벗어났습니다.',
  'product-coupon-price': '쿠폰 사용 가능한 가격범위를 벗어났습니다.',
  'shipping-coupon-price': '쿠폰 사용 가능한 가격범위를 벗어났습니다.',
  'cart-discount-only': '중복 할인 적용이 안되는 쿠폰이 사용되었습니다.',
  'product-discount-only': '중복 할인 적용이 안되는 쿠폰이 사용되었습니다.',
  'shipping-discount-only': '중복 할인 적용이 안되는 쿠폰이 사용되었습니다.',
  'censored-product': '해당 상품은 검수 중에 있습니다. 관리자에게 문의해주세요.',
  'exceeded-item-quantity': '현재 남은 수량이 카트에 담은 수량보다 적습니다.',
  'expired-variant': '만료된 세부 옵션입니다.',
  'invalid-product-bundle-type': '만료된 세부 옵션입니다.',
  'invalid-required-bundle-item-quantity': '잘 못된 세부 옵션입니다. 다시 선택하여 주세요.',
  'mismatching-price-type-rule': '시스템 오류입니다. 관리자에게 문의해주세요. (잘 못된 가격 설정)',
  'no-bundle-item': '존재하지 않는 번들입니다. 다시 선택하여 주세요.',
  'no-product': '존재하지 않는 상품입니다. 다시 선택하여 주세요.',
  'no-variant': '존재하지 않는 세부 품목입니다. 다시 선택하여 주세요.',
  'non-shippable-product': '시스템 오류입니다. 관리자에게 문의해주세요. (배송 방식 설정 오류)',
  'not-available-product': '선택하신 상품은 현재 판매 중지되었습니다',
  'not-available-variant': '선택하신 세부 옵션은 현재 판매 중지되었습니다.',
  'not-supported-shipping-method': '선택하신 배송방식을 지원하지 않는 상품입니다.',
  'required-bundle-item-required': '필수 선택 상품을 선택하지 않았습니다.',
  'shipping-method-not-allowed': '시스템 오류입니다. 관리자에게 문의해주세요. (무형 상품의 배송 방식 설정)',
  'shipping-method-required': '상품의 배송방식을 선택하여 주세요.',
  'sold-out-variant': '품절된 상품입니다.',
  'duplicated-coupon-usage': '이미 사용된 쿠폰입니다.',
  'duplicated-discount-usage': '이미 사용된 쿠폰입니다.',
  'duplicated-item': '이미 카트에 담겨있는 상품입니다.',
  'duplicated-shipping-discount-target': '이미 다른 쿠폰이 적용되어 있습니다.',
  'empty-cart': '카트에 담긴 상품이 없습니다.',
  'empty-cart-to-discount': '카트에 담긴 상품이 없습니다.',
  'erred-cart-to-discount': '카트에 오류가 발생하였습니다. 비우고 다시 진행해주세요.',
  'erred-item-to-discount': '상품 정보에 오류가 발생하였습니다. 다시 진행해주세요.',
  'expired-cart-coupon': '만기된 쿠폰이 사용되었습니다.',
  'expired-product-coupon': '만기된 쿠폰이 사용되었습니다.',
  'expired-shipping-coupon': '만기된 쿠폰이 사용되었습니다.',
  'inactive-cart-coupon': '사용이 중지된 쿠폰이 사용되었습니다.',
  'inactive-product-coupon': '사용이 중지된 쿠폰이 사용되었습니다.',
  'inactive-shipping-coupon': '사용이 중지된 쿠폰이 사용되었습니다.',
  'inactive-cart-discount': '사용이 중지된 할인이 사용되었습니다.',
  'inactive-product-discount': '사용이 중지된 할인이 사용되었습니다.',
  'inactive-shipping-discount': '사용이 중지된 할인이 사용되었습니다.',
  'invalid-cart-coupon-type': '시스템 오류입니다. 관리자에게 문의해주세요. (쿠폰 사용 설정)',
  'invalid-product-coupon-type': '시스템 오류입니다. 관리자에게 문의해주세요. (쿠폰 사용 설정)',
  'invalid-shipping-coupon-type': '시스템 오류입니다. 관리자에게 문의해주세요. (쿠폰 사용 설정)',
  'invalid-item-quantity-to-discount': '상품 수량이 1일 때 적용 가능한 쿠폰입니다.',
  'no-cart-coupon': '존재하지 않는 쿠폰입니다.',
  'no-product-coupon': '존재하지 않는 쿠폰입니다.',
  'no-shipping-coupon': '존재하지 않는 쿠폰입니다.',
  'no-item-to-discount': '존재하지 않는 상품 품목입니다. 다시 시도하여 주세요.',
  'no-shipment-to-discount': '배송 정보가 없습니다.',
  'no-shipping-policy': '시스템 오류입니다. 관리자에게 문의해주세요. (배송 정책 설정)',
  'no-tax': '시스템 오류입니다. 관리자에게 문의해주세요. (배송 국가 세금 정보)',
  'no-tax-detail': '시스템 오류입니다. 관리자에게 문의해주세요. (배송 국가 세율 정보)',
  'payment-method-required': '결제방식을 선택하여 주세요.',
  'shipping-address-required': '배송 주소를 입력하여 주세요.',
  'unnecessary-payment-method': '시스템 오류입니다. 관리자에게 문의해주세요. (결제 방식 설정이 필요없는 상품)',
  'order-usage-exceeded': '시스템 오류입니다. 관리자에게 문의해주세요. (스토어의 주문 사용량 초과)',
  'order-sync-failed': '주문 정보 저장에 실패하였습니다. 다시 시도해주세요.',
  'sync-in-progress': '정보 저장 중입니다. 잠시 후 다시 시도해주세요.',
  'unsynced-order': '정보 저장 중입니다. 잠시 후 다시 시도해주세요.',
  'invalid-customer-info': '해당 주문에 접근 권한이 없습니다.',
  'done-order': '거래가 완료된 주문입니다.',
  'expired-item': '만료일이 지난 상품입니다.',
  'fully-used-item': '사용 가능 횟수를 초과한 상품입니다.',
  'invalid-item-type': '시스템 오류입니다. 관리자에게 문의해주세요. (잘 못된 상품 타입)',
  'invalid-order-status': '현재 작업을 실행할 수 있는 주문상태가 아닙니다.',
  'refunded-item': '환불 과정 혹은 환불된 주문입니다.',
  'no-shipment': '배송 내역이 존재하지 않습니다.',
  'unauthorized-order-resource': '시스템 오류입니다. 관리자에게 문의해주세요. (입점 업체 환분 조건 설정)',
  'not-received-order': '현재 미수령 상태의 주문입니다.',
  'received-order': '현재 수령 상태의 주문입니다.',
  'invalid-value-precision': '시스템 오류입니다. 관리자에게 문의해주세요. (가격 소수점 자릿수 제한)',
  'not-registered-payment-method': '시스템 오류입니다. 관리자에게 문의해주세요. (지원하지 않는 결제 방식 사용)',
  'official-payment-method': '시스템 오류입니다. 관리자에게 문의해주세요. (결제 방식 설정 오류)',
  'custom-payment-method-not-allowed': '시스템 오류입니다. 관리자에게 문의해주세요. (결제 방식 설정 오류)',
  iamport: 'iamport',
  'invalid-payment-currency': '시스템 오류입니다. 관리자에게 문의해주세요. (통화 설정 오류)',
  'already-reviewed': '이미 등록된 리뷰가 있습니다.',
  unauthorized: '권한이 없습니다.'
}

class StoreApi {
  clayful = undefined
  errorCallback = undefined
  token = undefined
  customer = undefined
  wishListName = 'kit-heart'
  wishListDescription = '키트 상품 좋아요 여부를 위한 위시리스트'
  wishListOrigin = 'heart'

  /** clayful order status
   * cancelled
   // * over-paid
   // * over-refunded
   * paid
   * partially-refunded
   * placed
   * refunded
   // * under-paid
   // * under-refunded
   * */

  statuses = {
    placed: { key: 'placed', label: '결제 대기', isRefund: false, isCancel: false, isReview: false, isShipping: false, isDone: false, labelStyle: { backgroundColor: colors.grey.g100, variant: 'label2', color: colors.grey.g700 } },
    done: { key: 'done', label: '구매 확정', isRefund: false, isCancel: false, isReview: true, isShipping: true, isDone: true, labelStyle: { backgroundColor: colors.primary.light, variant: 'label1', color: colors.white } },
    paid: { key: 'paid', label: '배송 준비중', isRefund: true, isCancel: true, isReview: false, isShipping: false, isDone: false, labelStyle: { backgroundColor: colors.grey.g100, variant: 'label1', color: colors.primary.light } },
    shipped: { key: 'shipped', label: '출고 완료', isRefund: true, isCancel: false, isReview: true, isShipping: true, isDone: false, labelStyle: { borderColor: colors.primary.light, variant: 'label2', color: colors.primary.light } },
    'request-cancel': { key: 'request-cancel', label: '환불 요청중', isRefund: false, isCancel: false, isReview: false, isShipping: false, isDone: false, labelStyle: { backgroundColor: colors.grey.g100, variant: 'label2', color: colors.grey.g700 } },
    'under-refunded': { key: 'under-refunded', label: '환불 진행중', isRefund: false, isCancel: false, isReview: false, isShipping: true, isDone: false, labelStyle: { borderColor: colors.primary.light, variant: 'label2', color: colors.primary.light } },
    cancelled: { key: 'cancelled', label: '주문 취소', isRefund: false, isCancel: false, isReview: false, isShipping: false, isDone: true, labelStyle: { backgroundColor: colors.grey.g100, variant: 'label2', color: colors.grey.g700 } },
    refunded: { key: 'refunded', label: '환불 완료', isRefund: false, isCancel: false, isReview: false, isShipping: true, isDone: true, labelStyle: { backgroundColor: colors.grey.g100, variant: 'label2', color: colors.grey.g700 } },
    'partially-refunded': { key: 'refunded', label: '환불 완료', isRefund: false, isCancel: false, isReview: false, isShipping: true, isDone: true, labelStyle: { backgroundColor: colors.grey.g100, variant: 'label2', color: colors.grey.g700 } }
  }

  constructor (token, errorCallback) {
    const isSSR = typeof window === 'undefined'
    this.clayful = require(isSSR ? 'clayful' : 'clayful/client-js')
    this.clayful.config({ client: token })
    if (!isSSR && !this.clayful.plugins.request) {
      this.clayful.install('request', require('clayful/plugins/request-axios')(axios))
    }
    this.errorCallback = errorCallback
  }

  makeStatus (order) {
    if (order.status === 'paid' && !!order.fulfillments && order.fulfillments.length > 0 && order.receivedAt) return this.statuses.done
    if (order.status === 'paid' && !!order.fulfillments && order.fulfillments.length > 0) return this.statuses.shipped
    if (order.status === 'paid' && !!order.refunds && order.refunds.length > 0 && !order.refunds[0].cancellation) return this.statuses['request-cancel']
    return this.statuses[order.status]
  }

  async signin () {
    const token = getLocalItem('clayful-token')
    if (token) {
      const decoded = decodeJwt(token)
      if (decoded.exp * 1000 > new Date().getTime() + (1000 * 60 * 60)) {
        this.customer = getLocalItem('clayful-customer')
        this.token = token
        return
      }
    }
    const { success, data } = await window.api.request('post', 'clayful-v1', { create: true }, { path: 'login/', isErrorCallback: true, isLoginCallback: true })
    if (success) {
      setLocalItem('clayful-token', data.token)
      setLocalItem('clayful-customer', data.customer)
      this.customer = data.customer
      this.token = data.token
    }
  }

  updateMe ({ name, phone, mobile, email }, isCheckError, isCheck403) {
    return this.callClayful(
      this.clayful.Customer.updateMe, [{ name: { full: name }, phone, mobile }, { customer: this.token }], isCheckError, isCheck403
    )
  }

  getCollections ({ page, limit, sort }, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.Collection.list, [{ query: { page, limit, sort } }], isCheckError, isCheck403)
  }

  getCollection (id, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.Collection.get, [id], isCheckError, isCheck403)
  }

  getProduct (id, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.Product.get, [id], isCheckError, isCheck403)
  }

  getProducts ({ page = 1, limit, collection }, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.Product.list, [{ query: { page, limit, collection, available: true } }], isCheckError, isCheck403)
  }

  getPaymentMethods (isCheckError, isCheck403) {
    return this.callClayful(this.clayful.PaymentMethod.list, [], isCheckError, isCheck403)
  }

  getPaymentMethod (slag, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.PaymentMethod.get, ['clayful-iamport'], isCheckError, isCheck403)
  }

  getShippingPolicy (isCheckError, isCheck403) {
    return this.callClayful(this.clayful.ShippingPolicy.list, [{ query: { raw: true } }], isCheckError, isCheck403)
  }

  getShippingMethod (id, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.ShippingMethod.get, [id], isCheckError, isCheck403)
  }

  getReviews ({ id, page, limit, sort = '-createdAt' }, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.Review.listPublished, [{ query: { product: id, page, limit, sort } }], isCheckError, isCheck403)
  }

  getReview (id, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.Review.getPublished, [id], isCheckError, isCheck403)
  }

  getReviewCount (id, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.Review.countPublished, [{ query: { product: id } }], isCheckError, isCheck403)
  }

  getCart ({ address, items }, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.Cart.getForMe, [{ address, items }, { customer: this.token }], isCheckError, isCheck403)
  }

  getOrder (orderId, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.Order.getForMe, [orderId, { customer: this.token }], isCheckError, isCheck403)
  }

  getOrders ({ status, search, limit, page, sort, done, received }, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.Order.listForMe, [{ customer: this.token, query: { status, search, limit, page, sort, done, received } }], isCheckError, isCheck403)
  }

  addCartItem ({ id, variant, quantity, shippingId }, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.Cart.addItemForMe, [{ product: id, variant, quantity, shippingMethod: shippingId }, { customer: this.token }], isCheckError, isCheck403)
  }

  removeCart (isCheckError, isCheck403) {
    return this.callClayful(this.clayful.Cart.emptyForMe, [{ customer: this.token }], isCheckError, isCheck403)
  }

  removeCartItem (id, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.Cart.deleteItemForMe, [id, { customer: this.token }], isCheckError, isCheck403)
  }

  updateCartItem ({ id, quantity }, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.Cart.updateItemForMe, [id, { quantity }, { customer: this.token }], isCheckError, isCheck403)
  }

  createWishList ({ name, description, meta }, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.WishList.createForMe, [{ name, description, meta }, { customer: this.token }], isCheckError, isCheck403)
  }

  removeWishListAdmin (id, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.WishList.delete, [id, {}], isCheckError, isCheck403)
  }

  removeWishList (id, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.WishList.deleteForMe, [id, { customer: this.token }], isCheckError, isCheck403)
  }

  addWishItem ({ id, product }, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.WishList.addItemForMe, [id, { product }, { customer: this.token }], isCheckError, isCheck403)
  }

  removeWishItem ({ id, product }, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.WishList.deleteItemForMe, [id, product, { customer: this.token }], isCheckError, isCheck403)
  }

  getWishListCount (isCheckError, isCheck403) {
    return this.callClayful(this.clayful.WishList.count, [], isCheckError, isCheck403)
  }

  getAllWishList ({ page, limit }, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.WishList.list, [{ query: { page, limit } }], isCheckError, isCheck403)
  }

  getWishList (isCheckError, isCheck403) {
    return this.callClayful(this.clayful.WishList.listForMe, [{ customer: this.token }], isCheckError, isCheck403)
  }

  getWishItems ({ id, limit, page }, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.WishList.listProductsForMe, [id, { customer: this.token, query: { limit, page } }], isCheckError, isCheck403)
  }

  updateWishList ({ id, meta }, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.WishList.updateForMe, [id, { meta }, { customer: this.token }], isCheckError, isCheck403)
  }

  checkout ({ method, items, shipping, billing, request, meta }, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.Cart.checkoutForMe,
      ['order', { paymentMethod: method, items, address: { shipping, billing }, request, currency: 'KRW', meta }, { customer: this.token }],
      isCheckError, isCheck403
    )
  }

  async transactionPayment ({ order_id, merchant_uid, imp_uid }, isCheckError, isCheck403) {
    // /api/v1/clayful/orders/{id}/payment/complete/
    return await window.api.request(
      'post', 'clayful-v1',
      { imp_uid, merchant_uid },
      { path: `orders/${order_id}/payment/complete/`, isCheckError, isCheck403 })
  }

  /**
   items: [{ item: item_id, quantity: 2 }]
   * */
  requestRefund ({ id, reason, items, shipments }, isCheckError, isCheck403) {
    return this.callClayful(
      this.clayful.Order.requestRefundForMe,
      [id, { reason, items, shipments }, { customer: this.token }],
      isCheckError, isCheck403
    )
  }

  cancelOrder ({ id, reason }, isCheckError, isCheck403) {
    return this.callClayful(this.clayful.Order.cancelForMe, [id, { reason }, { customer: this.token }], isCheckError, isCheck403)
  }

  addReview ({ orderId, productId, title, body, images, rating, isAgree }, isCheckError, isCheck403) {
    return this.callClayful(
      this.clayful.Review.createForMe,
      [{ order: orderId, product: productId, title, body, images, rating, meta: { is_agree_marketing: isAgree } }, { customer: this.token }],
      isCheckError, isCheck403
    )
  }

  updateReview ({ id, title, body, images, rating, isAgree }, isCheckError, isCheck403) {
    return this.callClayful(
      this.clayful.Review.update,
      [id, { title, body, images, rating, meta: { is_agree_marketing: isAgree } }, { customer: this.token }],
      isCheckError, isCheck403
    )
  }

  completeOrder (orderId, isCheckError, isCheck403) {
    return this.callClayful(
      this.clayful.Order.markAsReceivedForMe,
      [orderId, { customer: this.token }],
      isCheckError, isCheck403
    )
  }

  uploadImage ({ file, model, application }, isCheckError, isCheck403) {
    const params = new FormData()
    params.append('file', file)
    params.append('model', model) /** 'Customer' || 'Review' || 'ReviewComment' || 'WishList' */
    params.append('application', application) /** String || 'avatar' || 'images' */
    return this.callClayful(
      this.clayful.Image.createForMe, [params, { customer: this.token }], isCheckError, isCheck403
    )
  }

  async hoverHeart ({ id, product, isHeart }) {
    if (isHeart) return await this.removeWishItem({ id, product }, true, false)
    else return await this.addWishItem({ id, product }, true, false)
  }

  async callClayful (api, payload, isCheckError = true, isCheck403 = true) {
    try {
      const { data, headers } = await api(...payload)
      return { success: true, data, headers }
    } catch (error) {
      console.log('clayful error : ', error, typeof error, error.message, error.data)
      if (isCheckError) {
        let is403 = false
        if (isCheck403 && (error.status === 401 || error.status === 403)) {
          is403 = true
          this.token = undefined
          this.customer = undefined
          setLocalItem('clayful-customer')
          setLocalItem('clayful-token')
          await this.signin()
          if (this.token) return await this.callClayful(api, payload, true, false)
        }
        if (!is403 && isCheckError && this.errorCallback) this.errorCallback(error)
      }
      return { success: false, error }
    }
  }

  async callbackPayment ({ order_id, imp_success, merchant_uid, imp_uid, error_msg, router, dispatch, setAlert, errorCallback }) {
    if (imp_success) {
      const { success, data } = await this.transactionPayment({ order_id, merchant_uid, imp_uid })
      if (success) {
        // const cartResponse = await this.getCart({})
        // console.log(cartResponse, data)
        setLocalItem('sirs-order-items')
        TagManager.dataLayer({ dataLayer: { event: 'gtm_event_store_purchase' } })
        router.push(`/store/order/complete/${order_id}`)
      } else {
        console.log('callback error - ', data)
        dispatch(setAlert({
          body: error_msg || '결제에 실패하였습니다.',
          onClick: () => router.replace('/store/cart'),
          onClose: () => router.replace('/store/cart')
        }))
      }
    } else {
      dispatch(setAlert({
        body: error_msg || '결제에 실패하였습니다.',
        onClick: () => errorCallback ? errorCallback() : router.replace('/store/cart'),
        onClose: () => errorCallback ? errorCallback() : router.replace('/store/cart')
      }))
    }
  }
}

export default StoreApi
