import { colors } from 'libs/theme'

export const appDownloadLink = {
  ios: 'https://itunes.apple.com/us/app/keynote/id1587599983?mt=8',
  android: 'https://play.google.com/store/apps/details?id=kr.dearsirs'
}

export const heights = {
  footer: 74,
  header: 60,
  maxWidth: 430
}

export const faqTypes = [
  { key: 'type1', name: '주문 및 배송' },
  { key: 'type2', name: '결제' },
  { key: 'type3', name: '비대면 진료' },
  { key: 'type4', name: '전문가 Q&A' }
]

export const faqs = [
  {
    type: 'type1',
    key: 'faq1-1',
    title: '비회원도 서비스 이용이 가능한가요?',
    content: '비회원도 저널 및 일반 제품 구매는 가능합니다. 다만, 원격 진료 및 전문의약품 배송이나 전문가(의사, 약사)에게 문의를 하기 위해서는 회원 가입이 필수입니다.'
  },
  {
    type: 'type1',
    key: 'faq1-2',
    title: '처방받은 이후 전문의약품 배송까지는 얼마나 걸리나요?',
    content: '썰즈는 택배로 의약품을 배송합니다. 처방전이 발급된 명일(평일 기준)에 약품 배송이 시작됩니다. 택배사의 사정에 따라 다르지만 택배 배송에 2일 정도 소요됨을 가정하면, 처방받은 이후 빠르면 3일, 늦으면 4일 내에는 받으실 수 있습니다. (택배사의 사정에 따라 달라질 수 있습니다.)'
  },
  {
    type: 'type1',
    key: 'faq1-3',
    title: '약 배송이 시작되었는데 배송지를 변경하려면 어떻게 해야 하나요?',
    content: 'Sir’s 고객센터로 문의 주시면 해결해 드리겠습니다.'
  },
  {
    type: 'type1',
    key: 'faq1-4',
    title: '배송이 가능한 지역은 어디인가요?',
    content: '전국 어디든 배송이 가능합니다.'
  },
  {
    type: 'type1',
    key: 'faq1-5',
    title: '택배가 분실된 것 같습니다.',
    content: 'Sir’s 고객센터로 문의 주시면 해결해 드리겠습니다.'
  },
  {
    type: 'type1',
    key: 'faq1-6',
    title: '약국에 처방전 전송은 어떻게 하나요?',
    content: '의약품 원격 배송을 신청하시는 경우, Sir’s 파트너 의사분이 직접 약국으로 처방전을 전송합니다. 따라서 처방전 전송에 대해서는 신경 쓰시지 않아도 됩니다.'
  },
  {
    type: 'type1',
    key: 'faq1-7',
    title: '전문의약품 배송은 합법인가요?',
    content: 'Sir’s의 약 배송 서비스는 보건복지부 공고 제2020-177호, 제2020-889호에 따라 2월24일부터 별도 종료 시까지 감염병의 예방 및 관리에 관한 법률」제4조 및 제49조의3, 보건의료기본법 제39조, 제40조 및 제44조, 의료법 제59조제1항에 근거하여 합법적으로 이용이 가능한 서비스입니다.'
  },
  {
    type: 'type2',
    key: 'faq2-1',
    title: '원격 진료 및 의약품 결제 방식은 어떻게 되나요?',
    content: 'Sir’s에서는 신용카드 등록을 통한 결제 서비스를 제공하고 있습니다. 의사나 약사에게 따로 금액을 지불하실 필요는 없습니다.'
  },
  {
    type: 'type2',
    key: 'faq2-2',
    title: '일반 제품 결제는 어떻게 하나요?',
    content: '일반 제품들(영양제, 건강기능식품 등)은 현재 사이트에서는 판매하고 있지 않습니다. 쿠팡이나 네이버스마트스토어 등에서 구매하실 수 있으며, 해당 커머스에서 제공하는 모든 결제방식으로 결제가 가능합니다.'
  },
  {
    type: 'type3',
    key: 'faq3-1',
    title: '현재 비대면 진료 및 처방이 가능한가요?',
    content: '썰즈의 비대면 진료 및 의약품 배송 서비스는 보건복지부 공고 제2020-177호, 제2020-889호에 따라 2020년 2월24일부터 별도 종료 시까지 「감염병의 예방 및 관리에 관한 법률」 제4조 및 제49조의3, 보건의료기본법 제39조, 제40조 및 제44조, 의료법 제59조제1항에 근거하여 이용가능한 서비스입니다.'
  },
  {
    type: 'type3',
    key: 'faq3-2',
    title: '진단서 등 처방전 이외에 서류는 어떻게 받을 수 있나요?',
    content: '고객센터로 문의하시면 도와드리겠습니다.<br />유선 : 1668-3861<br />채팅 : 카카오톡에서 ‘썰즈’ 검색<br />운영시간 : 오전 9시 30분 ~ 오후 6시 30분'
  },
  {
    type: 'type3',
    key: 'faq3-3',
    title: '영상 통화 또는 일반 통화 중 어떤 방식으로 비대면 진료를 하나요?',
    content: '영상 통화와 일반 통화 모두 합법적인 비대면 진료 방법으로, 현재 썰즈에서는 일반 전화 통화로만 비대면 진료를 제공하고 있습니다.'
  },
  {
    type: 'type3',
    key: 'faq3-4',
    title: '진료 가능 시간이 정해져 있나요?',
    content: '진료 시간은 오전 10시부터 오후 7시까지이며, 예약 가능한 시간은 썰즈와 제휴된 병원과 의사분들의 상황에 따라 달라질 수 있습니다. 진료 신청 시 진료 가능한 시간을 확인하실 수 있습니다.'
  },
  {
    type: 'type3',
    key: 'faq3-5',
    title: '진료의 범위가 어떻게 되나요?',
    content: '썰즈에서는 남성 질환에 집중하여 비대면 진료와 전문의약품 처방 서비스를 제공하고 있습니다. 주 진료분야는 탈모, 불면증, 여드름이며, 이외 질환에 대한 진료는 어려울 수 있습니다.'
  },
  {
    type: 'type4',
    key: 'faq4-1',
    title: '질문할 수 있는 범위가 정해져 있나요?',
    content: '질문의 범위는 없습니다. 건강과 관련하거나 약, 영양제와 관련한 어떤 질문을 하셔도 괜찮습니다.'
  },
  {
    type: 'type4',
    key: 'faq4-2',
    title: '질문 시, 답변은 언제 되나요?',
    content: 'Sir’s에서는 질문 주신 사항들에 대해 24시간 내에 답변하는 것을 원칙으로 하고 있습니다.'
  },
  {
    type: 'type4',
    key: 'faq4-3',
    title: '답변 해주시는 분들은 어떤 분들인가요?',
    content: 'Sir’s의 정식 파트너인 의사 및 약사 분들이 질문에 답변을 달아주십니다.'
  },
  {
    type: 'type4',
    key: 'faq4-4',
    title: 'Q&A로 원격 진료도 가능한가요?',
    content: 'Q&A에서 진료는 불가능합니다. 진료를 위해서는 Sir’s의 원격 진료 서비스를 이용하시거나, 병원에 직접 방문하시길 바랍니다.'
  }
]

export const reservationStatus = {
  '-1': { title: '매칭 실패', subtitle: '', description: '진료 취소' },
  0: { title: '취소', description: '진료 취소', style: { variant: 'flat', color: colors.grey.g200, fontColor: colors.grey.g700 } },
  1: { title: '담당의가 예약을 확인 중입니다.', subtitle: '예약 확정시 알림톡으로 안내해드리겠습니다.', description: '접수 대기중', style: { variant: 'flat', color: colors.primary.light, fontColor: colors.white } },
  2: { title: '담당의가 예약을 확인 중입니다.', subtitle: '예약 확정시 알림톡으로 안내해드리겠습니다.', description: '접수 대기중', style: { variant: 'flat', color: colors.primary.light, fontColor: colors.white } },
  3: { title: '#{clinic} #{doctor} 전문의 진료 예정입니다.', description: '예약 완료', style: { variant: 'flat', color: colors.primary.light, fontColor: colors.white } },
  4: { title: '#{clinic} #{doctor} 전문의의 진료가 완료되었습니다.', description: '결제 대기', style: { variant: 'outline', color: colors.primary.light, fontColor: colors.primary.light } },
  5: { title: '#{clinic} #{doctor} 전문의의 진료가 완료되었습니다.', description: '결제 완료', style: { variant: 'outline', color: colors.primary.light, fontColor: colors.primary.light } }
  // 6: { title: '약국에서 처방전을 확인하고 있습니다.', description: '약제비 청구 대기' }
}

export const reservationTabs = [
  { key: 'list', status: '-1,1,2,3', name: '예정 진료', noDateText: '예약 내역이 없습니다' },
  { key: 'waiting', status: '4', name: '결제 대기', noDateText: '결제 대기 중인 내역이 없습니다' },
  { key: 'complete', status: '5', name: '완료된 진료', noDateText: '진료 내역이 없습니다' }
]

export const metas = {
  hairloss: {
    title: '썰즈 sir’s - 탈모 (Hair Loss)',
    description: '남성의 탈모를 막아주고 회복을 돕는 치료제를 처방합니다. 탈모는 장기적인 관리와 지속적인 관심이 해결책입니다.'
  },
  acne: {
    title: '썰즈 sir’s - 여드름 (Acne)',
    description: '여드름은 청춘의 상징이지만, 적절한 관리를 통해 흉터는 남기지 말아야 합니다. 썰즈는 여드름 완화를 위한 의약품들을 제공합니다.'
  },
  insomnia: {
    title: '썰즈 sir’s - 불면증 (Insomnia)',
    description: '자고 싶을 때 잘 자야 건강합니다. 썰즈에서는 숙면을 위한 전문의약품으로 멜라토닌을 처방합니다.'
  },
  finasteride: {
    title: '썰즈 sir’s - 탈모약 처방',
    description: '프로페시아 계열 남성 탈모 치료제'
  },
  dutasteride: {
    title: '썰즈 sir’s - 탈모약 처방',
    description: '아보다트 계열 남성 탈모 치료제'
  },
  cleocin: {
    title: '썰즈 sir’s - 여드름약 처방',
    description: '크레오신으로 유명한 여드름 치료제'
  },
  isotinone: {
    title: '썰즈 sir’s - 여드름약 처방',
    description: '이소티논으로 유명한 여드름 치료제'
  },
  melatonin: {
    title: '썰즈 sir’s - 불면증약 처방',
    description: '자연적인 수면을 유도하는 수면유도제'
  }
}

export const prices = {
  finasteride: [
    { title: 'F 탈모약(오리지널)', price: '월 51,000원부터' },
    { title: 'F 탈모약(복제약)', price: '월 11,100원부터' },
    { title: 'D 탈모약(오리지널)', price: '월 23,400원부터' },
    { title: 'D 탈모약(복제약)', price: '월 15,000원부터' }
  ],
  cleocin: [
    { title: '바르는 여드름약', price: '개당 13,000원부터' },
    { title: '먹는 여드름약', price: '월 15,000원부터' }
  ],
  melatonin: [
    { title: '수면유도제', price: '월 12,000원부터' }
  ]
}

export const reportQuestions = {
  question: [
    '질문과 동떨어진 내용',
    '정보 및 설명이 부정확함',
    '오해 및 혼란의 소지가 있음',
    '오탈자 등 작성상 오류'
  ],
  reply: [
    '광고, 홍보 및 영리목적',
    '혼란을 야기하는 비전문적 내용',
    '음란, 외설적 내용, 청소년 유해',
    '욕설, 비방, 차별, 혐오 조장',
    '개인정보 노출 및 거래 유도',
    '도배 및 스팸성'
  ]
}

export const journalItems = {
  tags: {
    main: '저널#메인'
  }
}

export const purifyItems = {
  tags: ['div', 'iframe', 'p', 'br', 'span', 'strong', 'img', 'a', 'b', 'u', 'ul', 'li'],
  attr: ['target', 'onclick']
}

export const storeItems = {
  journalTags: {
    subjectMain: { size: 1, tag: '홈메인#메인' },
    subjectList: { size: 3, tag: '홈메인#리스트' },
    section1: { size: 5, tag: '홈메인#저널섹션1' }
  }
}

export const mainItems = {
  banner: {
    popup: '홈배너#팝업',
    slide: '홈배너#슬라이드'
  }
}

export const communityItems = {
  tagTitle: '썰즈톡제목#',
  qna: {
    main: { tag: '썰즈톡#메인', size: 6 },
    question: { tag: '썰즈톡#질문', size: 5 }
  },
  journal: {
    hair: { tag: '썰즈톡#탈모약', size: 6 },
    hair2: { tag: '썰즈톡#탈모약체크', size: 6 },
    performance: { tag: '썰즈톡#퍼포먼스', size: 6 }
  }
}

export const doctors = [
  {
    name: '유재호 원장',
    hospital: '가온삼성비뇨의학과의원',
    image: '/images/doctors/doctor-1.jpg'
  },
  {
    name: '이정훈 원장',
    hospital: '이로이의원',
    image: '/images/doctors/doctor-2.jpg'
  },
  {
    name: '김진오 원장',
    hospital: '뉴헤어모발성형외과의원',
    image: '/images/doctors/doctor-3.jpg'
  },
  {
    name: '박수환 원장',
    hospital: '나인비뇨의학과의원',
    image: '/images/doctors/doctor-4.jpg'
  },
  {
    name: '조재현 원장',
    hospital: '수앤모성형외과의원',
    image: '/images/doctors/doctor-5.jpg'
  },
  {
    name: '송혜린 원장',
    hospital: '신소애피부과',
    image: '/images/doctors/doctor-6.jpg'
  },
  {
    name: '장기현 원장',
    hospital: '밴스의원',
    image: '/images/doctors/doctor-7.jpg'
  },
  {
    name: '강대영 원장',
    hospital: '예뻐진의원',
    image: '/images/doctors/doctor-8.jpg'
  }
]

export const treatmentCategories = [
  {
    depth: -1,
    tags: {
      subjectMain: { size: 1, tag: '시술메인#메인' },
      subjectList: { size: 3, tag: '시술메인#리스트' },
      plasticMain: { size: 1, tag: '시술메인#얼굴윤곽메인' },
      plasticList: { size: 3, tag: '시술메인#얼굴윤곽' }
    }
  },
  {
    depth: 0,
    key: 'hair',
    name: '탈모',
    color: 'stylish',
    icon: '/images/icons/hair-care.svg',
    meta: {
      title: '썰즈 - 탈모 상담'
    }
  },
  {
    depth: 0,
    key: 'urology',
    name: '성기능',
    color: 'enjoy',
    icon: '/images/icons/urinary-care.svg',
    meta: {
      title: '썰즈 - 성기능 상담'
    }
  },
  {
    depth: 0,
    key: 'skin',
    name: '피부',
    color: 'stylish',
    icon: '/images/icons/skin-care.svg',
    meta: {
      title: '썰즈 - 피부 상담'
    }
  },
  {
    depth: 0,
    key: 'dental',
    name: '치아',
    color: 'stylish',
    icon: '/images/icons/dental-care.svg',
    meta: {
      title: '썰즈 - 치아 상담'
    }
  },
  /* {
    depth: 0,
    key: 'body',
    name: '바디',
    color: 'stylish',
    icon: '/images/icons/body-care.svg',
    meta: {
      title: '썰즈 - 바디 상담'
    }
  }, */
  {
    isEvent: false,
    depth: 1,
    parent: 'hair',
    key: 'implant',
    name: '모발이식',
    question: '나에게 맞는 모발이식은 뭘까?',
    card: {
      color: 'stylish',
      tag: 'HAIR',
      title: '모발이식',
      body: '건강한 모발이 밀집되어 있는 부위의 모낭을 채취하여 탈모가 생긴 부위에 이식하는 수술입니다. 절개식과 비절개식으로 구분됩니다.',
      doctor: {
        image: '/images/treatment/hair-doctor.png',
        name: '홍주형 원장',
        hospital: '모힐의원'
      },
      event: {
        image: '/images/treatment/hair-question.png',
        title1: '탈모약도 이젠 힘들다고?',
        title2: '모발이식 이벤트 모두보기'
      }
    }
  },
  {
    isEvent: false,
    depth: 1,
    parent: 'hair',
    key: 'cure',
    name: '탈모치료',
    question: '나한테 적합한 탈모치료 프로그램?',
    card: {
      color: 'stylish',
      tag: 'HAIR',
      title: '탈모치료 프로그램',
      body: '두피 및 모발 상태 진단을 바탕으로 맞춤형 치료를 제공합니다. 탈모를 억제하고 모발 성장을 촉진하는 데에 집중하며, 개인 간 치료법은 다릅니다.',
      doctor: {
        image: '/images/treatment/hair-doctor2.png',
        name: '김진오 원장',
        hospital: '뉴헤어모발성형외과의원'
      },
      event: {
        image: '/images/treatment/hair-question.png',
        title1: '내게 맞는 탈모치료법은 뭘까?',
        title2: '탈모치료 이벤트 모두보기'
      }
    }
  },
  {
    isEvent: false,
    depth: 1,
    parent: 'hair',
    key: 'tattoo',
    name: '두피 문신',
    question: '두피 문신, 나도 해야할까?',
    isNoChildren: true,
    card: {
      color: 'stylish',
      tag: 'HAIR',
      title: '두피 문신',
      body: '천연 의료용 색소를 이용해, 두피의 빈 영역을 채우는 반영구 색소 문신입니다. 자연스러운 모습을 연출하며, 즉시 일상생활이 가능합니다.',
      doctor: {
        image: '/images/treatment/hair-doctor2.png',
        name: '김진오 원장',
        hospital: '뉴헤어모발성형외과의원'
      }
    }
  },
  {
    isEvent: false,
    depth: 1,
    parent: 'urology',
    key: 'plastic',
    name: '성기성형',
    question: '성기 성형의 종류는 뭐가 있을까?',
    card: {
      color: 'enjoy',
      tag: 'SEX',
      title: '성기 성형',
      body: '의학적으로 음경왜소증은 4cm 이하이며, 성기 성형은 질환보다 자신감 측면에서 고려할 수 있습니다. 음경 확대, 길이 연장, 귀두 확대, 복합수술 등의 방법이 존재합니다.',
      doctor: {
        image: '/images/treatment/urology-doctor.png',
        name: '박수환 원장',
        hospital: '나인비뇨의학과의원'
      }
    }
  },
  {
    isEvent: false,
    depth: 1,
    parent: 'skin',
    key: 'correction',
    name: '윤곽 보정',
    question: '윤곽 보정의 방법에는 뭐가 있을까?',
    card: {
      color: 'stylish',
      tag: 'SKIN',
      title: '윤곽 보정',
      body: '성형 수술 없이, 얼굴 윤곽을 갸름하게 다듬고 작아 보일 수 있는 시술을 소개합니다. 사각턱 보톡스, 슈링크, 인모드 리프팅, 윤곽 주사 등의 방법을 활용합니다.',
      doctor: {
        image: '/images/treatment/skin-doctor1.png',
        name: '장기현 원장',
        hospital: '밴스의원 잠실'
      }
    }
  },
  {
    isEvent: false,
    depth: 1,
    parent: 'skin',
    key: 'beard',
    name: '수염제모',
    question: '수염제모를 하면 편할까?',
    card: {
      color: 'stylish',
      tag: 'SKIN',
      title: '수염제모',
      body: '면도를 해도 얼굴에 거뭇거뭇한 자국이 그대로 남고, 면도기 날 독성으로 피부가 안 좋아지는 경험을 한다면, 제모를 통해 수염을 제거해 볼 수 있습니다.',
      doctor: {
        image: '/images/treatment/skin-doctor2.png',
        name: '조재현 원장',
        hospital: '수앤모성형외과의원'
      }
    }
  },
  {
    isEvent: false,
    depth: 1,
    parent: 'skin',
    key: 'microblading',
    name: '눈썹 문신',
    question: '눈썹 문신은 어떤 원리?',
    card: {
      color: 'stylish',
      tag: 'SKIN',
      title: '눈썹 문신',
      body: '눈썹은 첫인상의 80%를 차지할 정도로 사람의 인상에 큰 영향을 미칩니다. 반영구 눈썹 문신은 깔끔한 외모와 좋은 인상을 남기기 위한 좋은 옵션이 될 수 있습니다.',
      doctor: {
        image: '/images/treatment/skin-doctor3.png',
        name: '강대영 원장',
        hospital: '예뻐진의원 잠실'
      }
    }
  },
  {
    isEvent: false,
    depth: 1,
    parent: 'urology',
    key: 'premature',
    name: '조루수술',
    question: '조루수술의 종류는 뭐가 있을까?',
    card: {
      color: 'enjoy',
      tag: 'SEX',
      title: '조루수술',
      body: '조루증은 본인의 의지대로 사정을 조절할 수 없거나, 만족감을 느끼기 전에 사정이 일어나는 현상입니다. 심리, 약물로 치료가 안되면 수술을 고려할 수 있습니다.',
      doctor: {
        image: '/images/treatment/urology-doctor2.png',
        name: '유재호 원장',
        hospital: '가온삼성비뇨의학과의원'
      }
    }
  },
  {
    isEvent: false,
    depth: 1,
    parent: 'dental',
    key: 'whitening',
    name: '치아미백',
    question: '치아미백은 어떻게 할까?',
    card: {
      color: 'stylish',
      tag: 'DENTAL',
      title: '치아미백',
      body: '밝고 자신있는 미소가 사람의 첫인상을 좌우합니다. 치아 색으로 인해 밝게 웃는 것이 불편했다면, 치아미백으로 자신감 있는 웃음을 되찾을 수 있습니다.',
      doctor: {
        image: '/images/treatment/dental-doctor1.png',
        name: '박호산 원장',
        hospital: '압구정 수치과병원'
      }
    }
  },
  {
    isEvent: false,
    depth: 1,
    parent: 'body',
    key: 'moobs',
    name: '여유증 수술',
    question: '여유증이 뭘까?',
    card: {
      color: 'stylish',
      tag: 'BODY',
      title: '여유증 수술',
      body: '여유증이란 남자의 가슴이 여성처럼 봉긋하게 나오는 증상을 말합니다. 여유증에는 유선조직이 진짜로 발달된 여유증과 지방층이 많이 축적된 가성여유증이 있습니다.',
      doctor: {
        image: '/images/treatment/body-doctor1.png',
        name: '송혜린 원장',
        hospital: '신소애피부과'
      }
    }
  },
  {
    depth: 2,
    parent: 'implant',
    key: 'incision',
    name: '절개식',
    eventName: '절개 모발이식 이벤트',
    journalName: '절개에 대해 더 궁금하다면',
    tag: '시술#절개식',
    meta: {
      title: '썰즈 - 모발이식 절개 시술 상담'
    },
    descriptions: [
      {
        question: '절개식 모발이식이란?',
        items: [
          { type: 'description', body: '머리 뒷부분을 절개하여 추출한 피부에서 모낭을 채취한 뒤, 필요한 부위에 이식하는 시술입니다. 모낭을 정확하게 분리하여 이식하기 때문에 생착률이 좋은 편입니다.' },
          { type: 'image', url: '/images/treatment/incision.png' }
        ]
      },
      {
        question: '절개의 특징',
        items: [
          {
            type: 'table',
            items: [
              { th: '시술대상', td: '대량 이식에 용이합니다.' },
              { th: '비용', td: '1모당 1,000원~10,000원 사이로, 이식 모발 수가 많을수록 모당 가격은 낮아집니다.' },
              { th: '장점', td: '1. 비교적 저렴<br />2. 모발 성장 방향 예측이 가능<br />3. 비절개 대비 빠른 수술시간' },
              { th: '단점', td: '1. 절개 부위 흉터<br />2. 수술 후 통증과 긴 회복 기간' }
            ]
          }
        ]
      }
    ]
  },
  {
    depth: 2,
    parent: 'implant',
    key: 'rhinotomy',
    name: '비절개식',
    eventName: '비절개 모발이식 이벤트',
    journalName: '비절개에 대해 더 궁금하다면',
    tag: '시술#비절개식',
    meta: {
      title: '썰즈 - 모발이식 비절개 시술 상담'
    },
    descriptions: [
      {
        question: '비절개식 모발이식이란?',
        items: [
          { type: 'description', body: '머리 뒷부분을 삭발한 후, 절개 없이 모낭을 하나씩 적출하여 이식하는 방법입니다. 펀치로 적출하기 때문에 일자형 흉터가 남지 않고 비교적 통증이 적은 편입니다.' },
          { type: 'image', url: '/images/treatment/rhinotomy.png' }
        ]
      },
      {
        question: '비절개의 특징',
        items: [
          {
            type: 'table',
            items: [
              { th: '시술대상', td: '소량 이식에 용이합니다.' },
              { th: '비용', td: '1모당 1,000원~10,000원 사이로, 이식 모발 수가 많을수록 모당 가격은 낮아집니다.' },
              { th: '장점', td: '1. 비교적 적은 통증<br />2. 절개가 없어 흉터의 부담이 낮음' },
              { th: '단점', td: '1. 절개식 대비 높은 가격<br />2. 후두부의 모발 상태에 따라 수술 가능 여부 판단 필요' }
            ]
          }
        ]
      }
    ]
  },
  {
    depth: 2,
    parent: 'cure',
    key: 'injection',
    name: '두피주사',
    eventName: '두피주사 탈모치료 이벤트',
    journalName: '두피주사에 대해 더 궁금하다면',
    tag: '시술#두피주사',
    meta: {
      title: '썰즈 - 탈모치료 두피주사 시술 상담'
    },
    descriptions: [
      {
        question: '두피주사란?',
        items: [
          { type: 'description', body: '머리카락 재생에 필요한 성분을 주사하거나, 탈모 관련 인자를 억제하는 성분을 주사하여 탈모를 억제하고 모발 성장을 촉진하는 방식의 치료법입니다.' },
          { type: 'image', url: '/images/treatment/injection.png' }
        ]
      },
      {
        question: '두피주사제의 특징',
        items: [
          { type: 'number', title: '1. 두피 보톡스', body: '주름 개선 목적의 보툴리늄 톡신을 탈모 부위에 주사합니다. 2010년 성형외과학회지에서 탈모에서의 보톡스 적용이 연구보고되며 효과를 인정받고 있습니다.' },
          { type: 'number', title: '2. 사이토카인', body: '탈모 부위에 많은 ‘모낭세포파괴인자’를 억제하는 사이토카인과 함께 ‘모낭세포를 증식하는’ 사이토카인을 주사합니다.' },
          { type: 'number', title: '3. PRP (Platelet-Rich Plasma)', body: '자신의 혈액에서 혈소판을 분리하여 두피에 주입합니다. 혈소판의 다양한 성장인자와 사이토카인이 모낭 세포를 분화/증식/생성하는 데에 영향을 미쳐, 모근 강화와 모발 재생을 돕습니다.' }
        ]
      }
    ]
  },
  {
    depth: 2,
    parent: 'cure',
    key: 'magnetic',
    name: '자기장',
    eventName: '자기장 탈모치료 이벤트',
    journalName: '자기장 탈모치료에 대해 더 궁금하다면',
    tag: '시술#자기장',
    meta: {
      title: '썰즈 - 탈모치료 자기장 시술 상담'
    },
    descriptions: [
      {
        question: '자기장 탈모치료가 뭔가요?',
        items: [
          { type: 'description', body: '두피 주위에 자기장을 형성하여, 모낭세포를 활성화하고 미세혈관의 혈류량을 증가시켜 결과적으로 탈모의 치료 및 증모를 유도하는 치료방식입니다.' }
        ]
      },
      {
        question: '대표적인 자기장 치료, 헤어셀',
        items: [
          { type: 'image', url: '/images/treatment/magnetic.png', width: '190px' },
          {
            type: 'table',
            items: [
              { th: '치료대상', td: '주로 남성형 탈모에 효과가 있으나, 여성탈모와 항암치료로 인한 탈모에도 효과가 있습니다. 특히 젊은 시기, 탈모 초기에 더욱 효과가 좋습니다.' },
              { th: '치료효과', td: '6-8주 치료 시 탈모가 줄고 머리카락의 가늘어짐이 개선되며, 16-18주가 지나면 발모가 촉진되는 것으로 확인되었습니다.' },
              { th: '치료시간', td: '주 1회, 약 12분간 치료' },
              { th: '임상결과', td: '1. 환자 중 96.7%에서 머리카락 가늘어짐을 개선<br />2. 36주 이상 사용한 환자의 93.3%에서 평균 66.1%만큼의 모발이 재성장' }
            ]
          }
        ]
      }
    ]
  },
  {
    depth: 2,
    parent: 'cure',
    key: 'laser',
    name: '레이저',
    eventName: '레이저 탈모치료 이벤트',
    journalName: '레이저 탈모치료에 대해 더 궁금하다면',
    tag: '시술#레이저',
    meta: {
      title: '썰즈 - 탈모치료 레이저 시술 상담'
    },
    descriptions: [
      {
        question: '레이저로 탈모를 치료할 수 있나요?',
        items: [
          { type: 'description', body: '레이저의 특정 파장을 사용해서 세포의 재생산을 활성화시킬 수 있습니다. 탈모에 적합한 파장과 강도로 빛을 조절하여 모근의 혈액과 림프 순환을 증가시키고, 두피 염증도 완화시킬 수 있습니다.' }
        ]
      },
      {
        question: '대표적인 레이저 치료, 스마트룩스',
        items: [
          { type: 'image', url: '/images/treatment/laser.png', width: '190px' },
          {
            type: 'table',
            items: [
              { th: '치료대상', td: '단일 치료로도 사용되지만, 특히 모발이식 이후에 생착률 증대 및 통증/상처 치료에 큰 효과를 보입니다.' },
              { th: '치료시간', td: '주 3회, 약 20분간 치료' },
              { th: '파장종류', td: '635nm 파장은 피부 재생과 상처 회복, 830nm 파장은 항염작용과 통증 완화 작용을 합니다. 해당 파장들을 탈모에 적합하게 활용합니다.' }
            ]
          }
        ]
      }
    ]
  },
  {
    depth: 2,
    parent: 'plastic',
    key: 'expand',
    name: '음경확대',
    eventName: '음경확대 이벤트',
    journalName: '음경확대에 대해 더 궁금하다면',
    tag: '시술#음경확대',
    meta: {
      title: '썰즈 - 성기 성형 음경확대 시술 상담'
    },
    descriptions: [
      {
        question: '음경확대에 활용되는 재료',
        items: [
          { type: 'number', title: '1. 가공 진피(대체진피)', body: '동물 혹은 인체로부터 추출, 가공한 진피를 음경확대의 재료로 활용합니다.' },
          { type: 'number', title: '2. 자가 진피', body: '본인의 신체에서 조직을 채취하여 음경에 삽입합니다. 주로 천추 부위(허리 아래와 꼬리뼈 윗부분 사이)에서 채취합니다.' },
          { type: 'number', title: '3. 실리콘 보형물', body: '구슬형, 일자형, T자형 등 실리콘 보형물을 음경에 삽입합니다.' },
          { type: 'number', title: '4. 히알루론산 필러', body: '여성 미용성형에 활용되는 재료와 동일한 히알루론산 필러를 주입합니다.' },
          { type: 'number', title: '5. 자가지방', body: '환자의 복부, 둔부 등에서 추출한 지방을 정제 및 가공하여 주입합니다.' }
        ]
      }
    ]
  },
  {
    depth: 2,
    parent: 'plastic',
    key: 'edge',
    name: '귀두확대',
    eventName: '귀두확대 이벤트',
    journalName: '귀두확대에 대해 더 궁금하다면',
    tag: '시술#귀두확대',
    meta: {
      title: '썰즈 - 성기 성형 귀두확대 시술 상담'
    },
    descriptions: [
      {
        question: '귀두확대술의 목적은?',
        items: [
          { type: 'description', body: '음경 부위만 확대술을 하는 경우, 이식된 부위와 이식되지 않은 부위와의 단절 현상으로 부자연스러운 형태가 될 수 있습니다. 또한 조루가 심한 경우, 조루수술과 병행 시 조루 치료 효과를 극대화할 수 있습니다.' }
        ]
      },
      {
        question: '귀두확대의 종류',
        items: [
          { type: 'number', title: '1. 약물 주입 귀두 확대술', body: '귀두를 직접 절개하지 않고, 주삿바늘을 통해 귀두막과 귀두 실질 조직 사이의 미세한 층에 약물을 정교하게 주입합니다.' },
          { type: 'image', url: '/images/treatment/enlarge.png', width: '100%' },
          { type: 'number', title: '2. 조직 직접 이식', body: '신체 중 진피층이 두꺼운 천추 부위에서 진피를 채취하여 귀두 내부에 이식조직을 삽입합니다.' },
          { type: 'image', url: '/images/treatment/enlarge2.png', width: '100%' },
          {
            type: 'steps',
            items: [
              { step: '1.', body: '신체 중 진피층이 가장 두꺼운 천추 부위에서 진피 채취' },
              { step: '2.', body: '채취 채취한 조직에서 피부층과 진피, 지방층을 분리' },
              { step: '3.', body: '귀두에 0.5~0.7cm 정도 절개 후, 이식 조직 삽입' }
            ]
          }
        ]
      }
    ]
  },
  {
    depth: 2,
    parent: 'premature',
    key: 'p',
    name: '조루수술',
    eventName: '조루수술 이벤트',
    journalName: '조루수술에 대해 더 궁금하다면',
    tag: '시술#조루수술',
    meta: {
      title: '썰즈 - 조루수술 상담'
    },
    descriptions: [
      {
        question: '조루의 기준은 무엇인가요?',
        items: [
          {
            type: 'steps',
            items: [
              { step: '1.', body: '질 내에 삽입 후 1분 이내로 사정한다.' },
              { step: '2.', body: '사정 지연이 본인의 의지대로 어렵다.' },
              { step: '3.', body: '상기 증상들로 스트레스와 좌절감이 들고, 결국 성적 관계를 기피하는 부정적 자존감이 든다.' }
            ]
          }
        ]
      },
      {
        question: '조루수술은 어떻게 하나요?',
        items: [
          { type: 'description', body: '음경 귀두 부분의 감각이 비정상적으로 예민한 경우, 음경배부신경 절단술을 진행하게 됩니다. 음경의 배부 신경 일부를 차단하여 감각의 전달을 둔화시키고 결국 사정을 늦출 수 있습니다.' },
          { type: 'image', url: '/images/treatment/premature.png', width: '100%', description: '기존 포경수술 자리를 1.5~2cm 절개하여<br /> 노출한 배부 신경을 선택적으로 차단 후 봉합' }
        ]
      }
    ]
  },
  {
    depth: 2,
    parent: 'tattoo',
    key: 't',
    name: '두피 문신',
    eventName: '두피 문신 이벤트',
    journalName: '두피 문신에 대해 더 궁금하다면',
    tag: '시술#두피문신',
    meta: {
      title: '썰즈 - 두피 문신 시술 상담'
    },
    descriptions: [
      {
        question: '두피 문신 전후 사진',
        items: [
          {
            type: 'before&after',
            items: [
              { title: '1. 정수리', before: '/images/treatment/tatoo-bf1.png', after: '/images/treatment/tatoo-af1.png' },
              { title: '2. 가르마', before: '/images/treatment/tatoo-bf2.png', after: '/images/treatment/tatoo-af2.png' },
              { title: '3. 흉터', before: '/images/treatment/tatoo-bf3.png', after: '/images/treatment/tatoo-af3.png' }
            ]
          }
        ]
      },
      {
        question: '두피 문신(SMP)의 특징',
        items: [
          {
            type: 'table',
            items: [
              { th: '시술시간', td: '1 ~ 3시간' },
              { th: '시술대상', td: '정수리 탈모, 민머리 탈모, 가르마, M자형 탈모, 모발이식으로 인한 흉터 부위 등 다양하게 적용될 수 있습니다.' },
              { th: '효과', td: '일상생활이 바로 가능하며, 최소 5년 이상 유지되는 반영구적인 시술입니다.' }
            ]
          }
        ]
      }
    ]
  },
  {
    depth: 2,
    parent: 'correction',
    key: 'shurink',
    name: '슈링크',
    journalName: '슈링크에 대해 더 궁금하다면',
    tag: '시술#슈링크',
    meta: {
      title: '썰즈 - 얼굴 윤곽 보정 슈링크 상담'
    },
    descriptions: [
      {
        question: '슈링크가 뭔가요?',
        items: [
          { type: 'description', body: '슈링크는 고강도의 초음파 에너지를 피부 속 1.5mm~4.5mm 깊이에 집중시켜, 콜라겐과 탄력섬유 조직의 재생을 촉진하는 시술입니다. 레이저로 피부 깊이별 열 응고점을 생성하여, 응고점을 중심으로 리프팅 효과가 나타납니다.' },
          { type: 'image', url: '/images/treatment/shurink-description.png', width: '100%', description: '피부 속 일정한 깊이에 <b>열 응고점</b>을 만들어<br /><b>콜라겐 섬유 재생</b> 촉진' }
        ]
      },
      {
        question: '슈링크의 특징',
        items: [
          {
            type: 'table',
            items: [
              { th: '시술시간', td: '300샷 기준 10분 내외' },
              { th: '시술대상', td: '갸름한 턱 선과 V라인을 원하시는 분, 주름을 개선하고 싶으신 분, 피부 탄력을 되찾고 싶으신 분' },
              { th: '시술비용', td: '300샷 기준 30 ~ 60만원' },
              { th: '시술주기', td: '평균 3 ~ 6개월 추천' }
            ]
          }
        ]
      }
    ]
  },
  {
    depth: 2,
    parent: 'correction',
    key: 'inmode',
    name: '인모드',
    journalName: '인모드에 대해 더 궁금하다면',
    tag: '시술#인모드',
    meta: {
      title: '썰즈 - 얼굴 윤곽 보정 인모드 상담'
    },
    descriptions: [
      {
        question: '인모드가 뭔가요?',
        items: [
          { type: 'description', body: '인모드는 고주파 에너지와 전기 자극을 근막층까지 전달해서 지방세포를 파괴하고, 피부 재생 및 주름 개선 효과를 볼 수 있는 리프팅 시술입니다. 인모드에는 지방 제거 목적은 FX모드와 탄력 개선 목적인 FORMA 모드로 분류됩니다.' },
          {
            type: 'table',
            items: [
              { th: 'FX', td: '지방층에 순간적인 열과 전기 자극을 전달해 지방 세포를 사멸시킵니다. 손상된 지방 세포는 2-4주 내에 자연 배출됩니다.' },
              { th: 'FORMA', td: '피부 진피층에 강력한 열 자극을 통해 콜라겐, 엘라스틴, 히알루론산 등의 생성을 촉진하여, 주름개선과 리프팅 효과를 보입니다.' }
            ]
          }
        ]
      },
      {
        question: '인모드의 특징',
        items: [
          {
            type: 'table',
            items: [
              { th: '시술시간', td: '20 ~ 30분 이내' },
              { th: '시술대상', td: '이중턱으로 고민하시는 분, 1주 만에 즉각적 효과를 원하는 분, 갸름한 라인 개선을 원하는 분' },
              { th: '시술비용', td: '3회 기준 100만원 내외' },
              { th: '시술주기', td: '2 ~ 4주 기간 주기로 3회 추천' }
            ]
          }
        ]
      }
    ]
  },
  {
    depth: 2,
    parent: 'correction',
    key: 'injection',
    name: '윤곽주사',
    journalName: '윤곽주사에 대해 더 궁금하다면',
    tag: '시술#윤곽주사',
    meta: {
      title: '썰즈 - 얼굴 윤곽 보정 윤곽주사 상담'
    },
    descriptions: [
      {
        question: '윤곽주사는 어떤 원리인가요?',
        items: [
          { type: 'description', body: '윤곽 주사는 얼굴 지방을 분해해 주는 시술로, 얼굴에 있는 불필요한 지방을 분해하여 땀이나 소변을 통해 체외로 배출시킵니다. 원하는 얼굴 부위에 선택적으로 적용해서 부드러운 얼굴라인과 얼굴 축소를 기대할 수 있습니다.' },
          { type: 'image', url: '/images/treatment/injection-description.png', width: '100%', description: '지방을 분해하여 땀이나 소변으로 배출' }
        ]
      },
      {
        question: '윤곽주사의 특징',
        items: [
          {
            type: 'table',
            items: [
              { th: '시술시간', td: '5 ~ 10분 이내' },
              { th: '시술대상', td: '얼굴살이 빠지지 않아 스트레스를 받는 분, 빠른 시간 안에 V라인을 원하는 분, 지방 없는 작은 얼굴을 원하는 분, 얼굴 비대칭을 교정하고 싶으신 분' },
              { th: '시술비용', td: '10 ~ 100만원' }
            ]
          }
        ]
      }
    ]
  },
  {
    depth: 2,
    parent: 'whitening',
    key: 'w',
    name: '치아미백',
    journalName: '치아미백 대해 더 궁금하다면',
    tag: '시술#치아미백',
    meta: {
      title: '썰즈 - 치아미백 상담'
    },
    descriptions: [
      {
        question: '치아미백의 원리',
        items: [
          { type: 'description', body: '치아미백 약제의 주성분인 ‘과산화수소’가 구강 내에 침투하면 약리작용에 의해 산소를 방출하게 됩니다. 이때 방출되는 산소가 치아에 착색이 된 물질을 밝은 색으로 바꿔줌으로써 미백 효과를 냅니다.' },
          { type: 'image', url: '/images/treatment/whitening-description.jpg', width: '100%', description: '과산화수소가 상아질에 침투해<br />착색된 치아를 밝은 색으로 변환' }
        ]
      },
      {
        question: '치아 변색의 원인',
        items: [
          { type: 'description', body: '본래 치아는 태어날 때부터 맑고 투명합니다만, 흡연으로 인한 니코틴 착색, 커피, 초콜릿, 녹차, 김치, 카레 등에 의해 생활하면서 점차 변색이 되는 것이 일반적인 치아 변색의 원인입니다. 그 밖에도 외상에 의한 신경손상, 충치 또는 치아교정 후 변색, 노화, 항생제나 불소 복용 등 여러 원인으로 변색이 생길 수 있습니다.' }
        ]
      },
      {
        question: '치아미백의 특징',
        items: [
          {
            type: 'table',
            items: [
              { th: '시술시간', td: '1회 방문 시 약 1시간' },
              { th: '마취여부', td: '없음' },
              { th: '총 횟수', td: '통상적으로 3회 진행' },
              { th: '시술비용', td: '3회 기준 20만원 ~ 100만원' }
            ]
          }
        ]
      }
    ]
  },
  {
    depth: 2,
    parent: 'whitening',
    key: 'laminate',
    name: '라미네이트',
    journalName: '라미네이트 대해 더 궁금하다면',
    tag: '시술#라미네이트',
    meta: {
      title: '썰즈 - 치아 라미네이트 상담'
    },
    descriptions: [
      {
        question: '라미네이트의 원리',
        items: [
          { type: 'description', body: '라미네이트(laminate)는 얇은 판을 붙여 만든 합판을 뜻하며, 치과에서의 라미네이트는 색감이나 질감이 자연치아와 유사한 세라믹 100%의 재질로 만들어 치아 표면에 부착하는 시술입니다.' }
        ]
      },
      {
        question: '라미네이트에는 치아 삭제가 필수인가?',
        items: [
          { type: 'description', body: '라미네이트를 붙이기 위해서는 치아를 어느 정도 삭제하고 다듬는 과정이 필요합니다. 다만 치아배열이 고르고 돌출이 없는 경우, 치아 사이가 벌어지고 치열이 고른 경우, 시술 부위 치아가 작은 경우 등에는 무삭제 라미네이트가 가능한 경우도 있습니다.' }
        ]
      },
      {
        question: '라미네이트의 특징',
        items: [
          {
            type: 'table',
            items: [
              { th: '소요시간', td: '치아 본을 뜨기 위해 1주일 소요.<br />최근에는 당일 라미네이트가 가능한 병원도 있습니다.<br />시술 당일에는 치아 1개 당 10~20분 가량이 소요됩니다.' },
              { th: '마취여부', td: '없음' },
              { th: '유지기간', td: '10 ~ 15년' },
              { th: '시술가격', td: '40 ~ 150만원' }
            ]
          }
        ]
      }
    ]
  },
  {
    depth: 2,
    parent: 'beard',
    key: 'b',
    name: '수염제모',
    journalName: '수염제모 대해 더 궁금하다면',
    tag: '시술#수염제모',
    meta: {
      title: '썰즈 - 수염제모 상담'
    },
    descriptions: [
      {
        question: '수염제모의 원리',
        items: [
          { type: 'description', body: '레이저 제모는 수염 등 체모에 대한 근본적인 해결책으로, 피부에 비해 검은 멜라닌 색소에 반응하는 레이저를 통해 모발의 뿌리인 ‘모근\'과 털을 발생시키는 원인인 ‘모낭’에 레이저를 쏘아 파괴시키는 반영구적인 제모 방식입니다.' },
          { type: 'image', url: '/images/treatment/beard-description.png', width: '100%', description: '모발의 뿌리인 모근과 모낭 파괴' }
        ]
      },
      {
        question: '수염제모의 특징',
        items: [
          {
            type: 'table',
            items: [
              { th: '시술시간', td: '10분 내외' },
              { th: '마취여부', td: '마취크림 사용' },
              { th: '유지기간', td: '반영구' },
              { th: '시술비용', td: '5회 기준 30만원 내외' }
            ]
          }
        ]
      }
    ]
  },
  {
    depth: 2,
    parent: 'microblading',
    key: 'm',
    name: '눈썹 문신',
    journalName: '눈썹 문신에 대해 더 궁금하다면',
    tag: '시술#눈썹문신',
    meta: {
      title: '썰즈 - 눈썹 문신 상담'
    },
    descriptions: [
      {
        question: '눈썹 문신의 원리',
        items: [
          { type: 'description', body: '피부는 크게 표피-진피-피하지방-근육의 층으로 이루어져 있는데 눈썹 문신은 표피 맨 아래층과 진피층 상부에 상처를 내 잉크를 채워 넣게 됩니다. 표피층에 잉크를 주입하게 되면 잉크가 지속적으로 남아있지 못하고 탈각과 함께 사라지기 때문에 표피보다 아래에 위치한 진피에 잉크를 주입해 반영구적인 효과를 유지하게 되는 것이 반영구 눈썹 문신의 원리입니다.' },
          { type: 'image', url: '/images/treatment/microblading-description.png', width: '100%', description: '진피에 잉크를 주입해 반영구적인 효과' }
        ]
      },
      {
        question: '눈썹 문신의 특징',
        items: [
          {
            type: 'table',
            items: [
              { th: '시술시간', td: '1시간 내외' },
              { th: '마취여부', td: '마취크림 사용' },
              { th: '유지기간', td: '2 ~ 3년' },
              { th: '시술비용', td: '리터치 포함 10 ~ 30만원 내외' }
            ]
          }
        ]
      }
    ]
  },
  {
    depth: 2,
    parent: 'moobs',
    key: 'm',
    name: '여유증 수술',
    journalName: '여유증에 대해 더 궁금하다면',
    tag: '시술#여유증수술',
    meta: {
      title: '썰즈 - 눈썹 문신 상담'
    },
    descriptions: [
      {
        question: '여유증의 진단',
        items: [
          { type: 'description', body: '정상 남성의 경우 유방초음파에서 피부밑 지방층, 근육층으로 이루어지지만, 여성형 유방증 남성의 경우 지방층과 근육층 사이에 유선조직이 발달하여 여성과 비슷하게 가슴이 형성됩니다.' },
          { type: 'image', url: '/images/treatment/moobs-description.png', width: '100%' }
        ]
      },
      {
        question: '여유증의 유형',
        items: [
          {
            type: 'column',
            items: [
              { url: '/images/treatment/moobs1.png', description: '유선 조직이 크지 않고 유두 유륜 주변에서 발달' },
              { url: '/images/treatment/moobs2.png', description: '유선 조직이 전반적으로 발달하여 가슴이 나옴' },
              { url: '/images/treatment/moobs3.png', description: '가슴이 나옴과 동시에 처짐이 생김' },
              { url: '/images/treatment/moobs4.png', description: '유선 조직이 매우 발달하여 유두 유륜이 아래쪽으로 향함' }
            ]
          }
        ]
      },
      {
        question: '여유증 수술의 특징',
        items: [
          {
            type: 'table',
            items: [
              { th: '시술시간', td: '1 ~ 2시간' },
              { th: '마취여부', td: '수면마취' },
              { th: '회복기간', td: '입원 후 수술 당일 퇴원' },
              { th: '시술비용', td: '5회 기준 30만원 내외' }
            ]
          }
        ]
      }
    ]
  }
]

export const requestTimes = [
  { key: 'ANYTIME', name: '아무때나' },
  { key: 'MORNING', name: '오전' },
  { key: 'AFTERNOON', name: '오후' }
]

export const hosipitalCategories = [
  { key: 'info', name: '병원 정보' },
  { key: 'event', name: '이벤트' },
  { key: 'doctor', name: '의사 소개' }
]

export const weekItems = [
  { key: 'mon', name: '월' },
  { key: 'tue', name: '화' },
  { key: 'wed', name: '수' },
  { key: 'thr', name: '목' },
  { key: 'fri', name: '금' },
  { key: 'sat', name: '토' },
  { key: 'sun', name: '일' }
]

export const partners = [
  { name: '김땡땡 의사', hospital: '늘푸른비뇨의학과', image: '/images/sample-doctor.jpeg' },
  { name: '김땡땡 의사', hospital: '늘푸른비뇨의학과', image: '/images/sample-doctor.jpeg' },
  { name: '김땡땡 의사', hospital: '늘푸른비뇨의학과', image: '/images/sample-doctor.jpeg' }
]

export const journalCategories = [
  { key: 'hair', tag: '저널#hair', name: 'Hair', ko: '모발', image: '/images/category/hairloss.png', order: 0, color: 'stylish' },
  { key: 'sex', tag: '저널#sex', name: 'Sex', ko: '성기능', image: '/images/category/urinary.png', order: 3, color: 'enjoy' },
  { key: 'skin', tag: '저널#skin', name: 'Skin', ko: '피부', image: '/images/category/acne.png', order: 2, color: 'stylish' },
  { key: 'sleep', tag: '저널#sleep', name: 'Sleep', ko: '수면', image: '/images/category/insomnia.png', order: 1, color: 'relax' },
  { key: 'body', tag: '저널#body', name: 'Body', ko: '체형', image: '/images/category/body.png', order: 4, color: 'stylish' },
  { key: 'dental', tag: '저널#dental', name: 'Dental', ko: '구강', image: '/images/category/dental.png', order: 5, color: 'stylish' }
]

export const doctorRoles = [
  { key: 'representative', label: '대표원장', value: '대표원장' },
  { key: 'director', label: '원장', value: '원장' },
  { key: 'specialist', label: '전문의', value: '전문의' },
  { key: 'empty', label: '입력하지 않음', value: undefined }
]

export const QuestionCategoryPathNames = {
  hairloss: 'hairloss-clinic-questionnaire',
  insomnia: 'insomnia-clinic-questionnaire',
  acne: 'acne-clinic-questionnaire'
}

export const prescribeCategories = [
  { key: 'hairloss', name: '탈모', tag: '탈모', apiName: 'finasteride', isFast: true, meta: { title: '썰즈 - 탈모치료에 대해' } },
  { key: 'insomnia', name: '불면증', tag: '불면증', apiName: 'melatonin', isFast: false, meta: { title: '썰즈 - 불면증 치료에 대해' } },
  { key: 'acne', name: '여드름', tag: '여드름', apiName: 'cleocin', isFast: false, meta: { title: '썰즈 - 여드름 치료에 대해' } }
]

export const prescribeCategories2 = [
  { key: 'hair', name: '탈모', tag: '탈모', isFast: true, meta: { title: '썰즈 - 탈모치료에 대해' } },
  { key: 'sleep', name: '불면증', tag: '불면증', isFast: true, meta: { title: '썰즈 - 불면증치료에 대해' } },
  { key: 'acne', name: '여드름', tag: '여드름', isFast: true, meta: { title: '썰즈 - 여드름치료에 대해' } }
]

export const prescribeTags = {
  hairloss: '진료#탈모',
  insomnia: '진료#불면증',
  acne: '진료#여드름'
}

export const timeItems = {
  start: 10,
  end: 22,
  rest: [0],
  nightStart: 19,
  maxDay: 3
}
