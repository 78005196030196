import React, { memo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { setAlert } from 'store/default'
import { spaces, colors } from 'libs/theme'
import { heights } from 'libs/items'

import { Grid, Text } from 'components/elements'
import { Dialog } from 'components/elements/Dialog'
import { Button } from 'components/elements/Buttons'

const Alert = memo(() => {
  const dispatch = useDispatch()
  const { alert } = useSelector(state => state.default)

  const close = () => {
    dispatch(setAlert())
    if (alert.onClose) alert.onClose()
  }
  const click = () => {
    dispatch(setAlert())
    if (alert.onClick) alert.onClick()
  }

  const checkEnter = e => {
    if (e.key === 'Enter') {
      dispatch(setAlert())
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', checkEnter)
    return () => {
      window.removeEventListener('keydown', checkEnter)
    }
  }, [])

  if (!alert) return null

  return (
    <Dialog isOpen zIndex={1000000} close={close}>
      <Grid minWidth='250px' maxWidth={`${heights.maxWidth}px`}>
        <Grid padding={spaces.contents.s1.px} gap={spaces.contents.s1.px}>
          {
          alert.title && (
            <Grid columns='1fr'>
              <Text variant='title3' color={colors.grey.g800}>{alert.title}</Text>
            </Grid>
          )
        }
          <Grid>
            {
              typeof alert.body === 'string' ? <Text variant='subtitle3' color={colors.grey.g700} align='left' html={alert.body} />
                : alert.body
            }

          </Grid>
          {
          alert.type === 'confirm' ? (
            <Grid columns='repeat(2, 1fr)' gap={spaces.component.s3.px}>
              <Button color={colors.grey.g100} height='48px' width='100%' radius={spaces.radius.medium.px} onClick={close}>
                <Text variant='subtitle2' bold color={colors.primary.normal}>{alert.cancelText || '취소'}</Text>
              </Button>
              <Button color={colors.primary.dark} height='48px' width='100%' radius={spaces.radius.medium.px} onClick={click}>
                <Text variant='subtitle2' bold={false} color={colors.white}>{alert.buttonText || '확인'}</Text>
              </Button>
            </Grid>
          ) : (
            <Grid justify='center' columns='1fr'>
              <Button minwidth='143px' color={colors.primary.dark} height='48px' width='100%' radius={spaces.radius.medium.px} onClick={click}>
                <Text variant='subtitle1' bold={false} color={colors.white}>{alert.buttonText || '확인'}</Text>
              </Button>
            </Grid>
          )
        }
        </Grid>
      </Grid>
    </Dialog>
  )
}, () => {
  return true
})

export default Alert
