const regexes = {
  number: /[0-9]/,
  exceptNumber: /[^0-9]/g,
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  english: /[A-Za-z]/,
  special: /[!@#$%^&*()_+=-`~\\\][{}|';:/.,?><]/,
  exceptCardNumber: /[^0-9*]/g,
  koAndEn: /^[ㄱ-ㅎ|가-힣|a-z|A-Z]+$/g,
  exceptKoAndEn: /[^ㄱ-ㅎ|가-힣|a-z|A-Z]+$/g,
  contact: /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
  tag: /<\/?[^>]+(>|$)/g
}

export const regex = (type, str) => {
  if (regexes[type]) {
    return regexes[type].test(str)
  }
}

export const replaceRegex = (type, str, replace) => {
  if (regexes[type]) {
    return str.replace(regexes[type], replace)
  }
}

export const trim = str => {
  if (str === undefined || str === null) return ''
  else if (typeof str !== 'string') return String(str).trim()
  return str.trim()
}

export const makeComma = number => {
  const thisNumber = Number(number)

  if (isNaN(thisNumber)) return number
  if (thisNumber === 0 || thisNumber.length < 4) return thisNumber

  const reg = /(^[+-]?\d+)(\d{3})/
  let n = String(thisNumber)

  while (reg.test(n)) n = n.replace(reg, '$1,$2')

  return n
}

export const setProp = (name, prop, isImportant = false) => {
  return prop !== undefined
    ? `${name}: ${prop}${isImportant ? ' !important' : ''};`
    : ''
}

export const checkEmail = email => {
  if (!regexes.email.test(email)) {
    return false
  }
  return true
}

export const checkPassword = password => {
  if (password.length < 6) return '6자 이상 입력해주세요.'
  let count = 0
  if (regexes.english.test(password)) count += 1
  if (regexes.number.test(password)) count += 1
  if (regexes.special.test(password)) count += 1
  if (count < 2) return '영문, 숫자, 특수문자 중 2가지 이상을 조합하여 비밀번호를 입력해주세요.'
  return true
}

export const getUrlQuery = search => {
  const params = {}
  search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (str, key, value) { params[key] = value })
  return params
}
export const getUrlHash = search => {
  const params = {}
  search.replace(/[#&]+([^=&]+)=([^&]*)/gi, function (str, key, value) { params[key] = value })
  return params
}

export const decodeBase64 = str => {
  try {
    const decoded = decodeURIComponent(atob(str))
    return JSON.parse(decoded)
  } catch (error) {
    console.log(error)
    return {}
  }
}

export const decodeJwt = token => {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''))
    return JSON.parse(jsonPayload)
  } catch (error) {
    console.log(error)
    return false
  }
}

export const nl2br = str => {
  return str.replace(/\n/g, '<br />')
}

export const replaceAll = (str, target, replace) => {
  return str.split(target).join(replace)
}

export const findLastIndex = (arr, condition) => {
  let finded = -1
  for (let i = arr.length - 1; i >= 0; --i) {
    if (condition(arr[i])) {
      finded = i
      break
    }
  }
  return finded
}

export const uppercase = str => {
  return (str || '').toLocaleUpperCase()
}
export const lowercase = str => {
  return (str || '').toLowerCase()
}

export const shuffle = arr => {
  return arr.sort(() => Math.random() - 0.5)
}

export const copyText = async s => {
  return await window.navigator.clipboard.writeText(s)
}

export const removeIndex = (arr, index) => {
  return [
    ...arr.slice(0, index),
    ...arr.slice(index + 1)
  ]
}

export const random = max => {
  return Math.floor(Math.random() * max)
}
