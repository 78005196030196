import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import StoreApi, { errorMessages } from 'libs/clayful'
import { setAlert, setClayful } from 'store/default'

const ClayfulLayout = ({ children, isUser }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { env, user, clayful } = useSelector(state => state.default)
  const [isInit, setisInit] = useState(false)

  const errorCallback = (error) => {
    // const massage = error.data.body
    if (error.code) {
      dispatch(setAlert({ body: errorMessages[error.code] }))
      console.log('message', error.code)
    } else if (error.message) {
      dispatch(setAlert({ body: error.message }))
      console.log('message', error.message)
    } else if (error.errorCode?.validation) {
      console.log('validation', error.validation)
    } else {
      console.log(error)
    }
  }

  useEffect(() => {
    const settingClayful = async () => {
      const c = new StoreApi(env?.CLAYFUL_TOKEN, errorCallback)
      if (user) await c.signin()
      if (!c?.token && isUser) {
        dispatch(setAlert({
          body: '로그인이 필요한 페이지입니다.',
          onClick: () => router.replace(`/account?page=${encodeURIComponent(window.location.pathname + window.location.search)}`),
          onClose: () => router.replace(`/account?page=${encodeURIComponent(window.location.pathname + window.location.search)}`)
        }))
      }
      dispatch(setClayful(c))
    }
    if (!!user || user === null) settingClayful()
  }, [user])

  if (!clayful || (isUser && !clayful?.token)) return null

  return children
}

export default ClayfulLayout
