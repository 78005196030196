import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { colors, spaces } from 'libs/theme'
import { appDownloadLink } from 'libs/items'

import { Grid, Text } from 'components/elements'
import { Button } from 'components/elements/Buttons'

const PCMain = () => {
  const router = useRouter()
  const [isInit, setisInit] = useState(false)
  const tags = [
    { body: '전문의 24시간 Q&A', link: '/community/counsel' },
    { body: '비대면 진료', link: '/prescribe' },
    { body: '남성 의학 저널', link: '/community/journal' },
    { body: '남성 영양제', link: '/community/journal/89' },
    { body: '남성 시술/수술 정보', link: '/treatment' }
  ]
  useEffect(() => {
    setisInit(true)
  }, [])
  if (!isInit) return null
  return (
    <Grid height='100vh' align='center' justify='center'>
      <Grid width='325px' position='fixed'>
        <Button variant='text' width='82px' radius='0' onClick={() => router.push('/')}>
          <Grid isInline image='/images/logo-white.png' width='80px' height='40px' imageSize='contain' />
        </Button>
        <Grid marginTop='78px' lg={{ marginTop: '36px' }}>
          <Text color={colors.white} size='40px' display='inline-block' lineHeight='1.4'>
            남성 건강관리는<br /><Text size='40px' color={colors.white} bold>썰즈</Text>입니다.
          </Text>
        </Grid>
        <Grid marginTop='48px' lg={{ marginTop: '24px' }}>
          <Text color={colors.white} size='20px' lineHeight='1.4'>믿을 수 있는 전문 의료진과 함께 건강 고민 다 해결하세요.</Text>
        </Grid>
        <Grid marginTop='50px' display='flex' gap='10px' style={{ flexWrap: 'wrap' }}
              lg={{ marginTop: '25px' }}>
          {
            tags.map(t => (
              <Button key={t.body} color='#8298B6' height='30px' padding={`0 ${spaces.component.s4.px}`} onClick={() => router.push(t.link)}>
                <Text variant='body2' color={colors.grey.g800}>{t.body}</Text>
              </Button>
            ))
          }
        </Grid>
        <Grid marginTop='100px' lg={{ marginTop: '50px' }}>
          <Text color={colors.white} size='20px' lineHeight='1.4'>지금 앱 다운 받고,<br />썰즈와 건강관리 시작하세요!</Text>
        </Grid>
        <Grid marginTop='26px' lg={{ marginTop: '13px' }} columns='148px 148px' gap='11px'>
          <Button variant='flat' height='48px' color={colors.white} onClick={() => window.open(appDownloadLink.ios)}>
            <Grid columns='fit-content(100%) fit-content(100%)' justify='center' align='center' gap='8px'>
              <img src='/images/logo_apple.png' width='22px' height='22px' />
              <Text>앱 다운로드</Text>
            </Grid>
          </Button>
          <Button variant='flat' height='48px' color={colors.white} onClick={() => window.open(appDownloadLink.android)}>
            <Grid columns='fit-content(100%) fit-content(100%)' justify='center' align='center' gap='8px'>
              <img src='/images/logo_playstore.png' width='22px' height='22px' />
              <Text>앱 다운로드</Text>
            </Grid>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PCMain
